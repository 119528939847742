import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { getSearchListRecurringDatesByVideoId } from '../../services/content.service';
import { formatTimestamp } from '../../utils/datetime.util';

const RecurringEventDate = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  sx,
  error,
  disabled,
  videoId,
  handleLoading,
}) => {
  const [recurringData, setRecurringData] = useState([]);
  const [focused, setFocused] = useState(false);

  const handleChange = (e, explicitSet = false) => {
    if (explicitSet) {
      onChange(e);
    } else {
      onChange(e.target.value);
    }
  };

  useEffect(() => {
    getSearchListRecurringDatesByVideoId(videoId)
      .then((res) => {
        setRecurringData(res.data);
        handleChange(res.data[0].id, true);
        handleLoading();
      })
      .catch(() => {
        // nothing
      });
  }, []);

  return (
    <FormControl sx={{ width: '25%' }} error={error !== ''}>
      <InputLabel
        id={`field-${id}-select-label`}
        sx={{
          marginLeft: '10px',
          marginTop: focused || defaultValue ? 0 : '-8px',
          width: '200px',
          maxWidth: '200px',
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={id}
        name={name}
        value={defaultValue}
        label={label}
        onChange={handleChange}
        sx={sx}
        disabled={disabled}
        onOpen={() => setFocused(true)}
        onClose={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        {recurringData.map((k) => (
          <MenuItem key={`${id}-v-${k.id}`} value={k.id}>
            {formatTimestamp(k.timestamp, 'DD/MM/YYYY hh:mm A')}
          </MenuItem>
        ))}
      </Select>

      {error !== '' && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

RecurringEventDate.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  disabled: PropTypes.number,
  videoId: PropTypes.number,
  handleLoading: PropTypes.func,
};
RecurringEventDate.defaultProps = {
  sx: {},
  error: '',
  disabled: null,
  videoId: null,
  handleLoading: () => {},
};

export default RecurringEventDate;
