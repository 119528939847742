import React from 'react';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListArticleCategory from '../../components/content/list-article-category.component';

const ArticleCategories = () => {
  const pageTitle = PAGE_TITLE_LIST.CONTENT_ARTICLE_CATEGORIES;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListArticleCategory />
    </AppLayout>
  );
};

export default ArticleCategories;
