import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { getSearchListPackages } from '../../services/content.service';

const Packages = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  sx,
  error,
  disabled,
}) => {
  const [booksData, setBooksData] = useState([]);

  useEffect(() => {
    getSearchListPackages()
      .then((res) => {
        setBooksData(res.data);
      })
      .catch(() => {
        // nothing
      });
  }, []);

  return (
    <FormControl fullWidth error={error !== ''}>
      <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>

      <Select
        labelId="demo-simple-select-label"
        id={id}
        name={name}
        value={defaultValue}
        label={label}
        onChange={onChange}
        sx={sx}
        disabled={disabled}
      >
        {booksData.map((k) => (
          <MenuItem key={`${id}-v-${k.id}`} value={`${k.id}`}>
            {`${k.name}`}
          </MenuItem>
        ))}
      </Select>

      {error !== '' && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

Packages.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  disabled: PropTypes.number,
};
Packages.defaultProps = {
  sx: {},
  error: '',
  disabled: null,
};

export default Packages;
