import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Slide,
  Box,
} from '@mui/material';
import ListData from '../table-elements/list-data.component';
import { getListData } from '../../services/subscriptions.service';
import Iconify from '../common/iconify.component';
import { VIEW_SUBSCRIPTION_COLUMNS } from '../../config/module-configs/subscriptions.config';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ManageSubscriptions = ({ title, user, dataId, onCancel }) => {
  const columns = VIEW_SUBSCRIPTION_COLUMNS;
  const actions = [];

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const dataListAPICall = (newOptions = {}) => {
    let uri = '';
    uri += `?page=${newOptions?.page || options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (dataId) {
      uri += `&id=${dataId}`;
    }

    setOptions({ ...options, loading: true, searchValue: '' });

    getListData(uri)
      .then((res) => {
        let serialRow;

        if (res?.data?.rows) {
          serialRow = res.data.rows;
          serialRow = serialRow.map((tr, count) => ({
            ...tr,
            serial: options.page * options.rowsPerPage + count + 1,
          }));
        }

        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: serialRow || [],
          q: null,
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          q: null,
          error: true,
        });
      });
  };

  useEffect(() => {
    dataListAPICall();
  }, [options.reloadCounter]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      onClose={() => onCancel()}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        id="view-dialog-title"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {title}
        {!!dataId && user.profilePic && (
          <Box
            component="img"
            sx={{
              height: 30,
              width: 30,
              objectFit: 'cover',
              borderRadius: 15,
              display: 'inline',
              marginX: 1,
            }}
            src={user.profilePic}
          />
        )}
        {!!dataId && user.email}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        <Card>
          <ListData
            columns={columns}
            rows={options.rows}
            page={options.page}
            rowsPerPage={options.rowsPerPage}
            totalRows={options.totalRows}
            loading={options.loading}
            actions={actions}
            error={options.error}
            sortBy={options.sortBy}
            sortOrder={options.sortOrder}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSortChange={handleSortingChange}
          />
        </Card>
      </DialogContent>
    </Dialog>
  );
};

ManageSubscriptions.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ManageSubscriptions;
