import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Stack, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import JsFileDownloader from 'js-file-download';
import {
  VIEW_COLUMNS,
  LISTING_COLUMNS,
} from '../../config/module-configs/event-registrations.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import { getEventRegistrationByVideoId } from '../../services/content.service';
import ROUTE_PATH from '../../config/routes.config';
import Loading from '../table-elements/loading.component';
import ViewData from '../table-elements/view-data.component';
import RecurringEventDate from '../form-elements/recurring-event-date.component';
import { PAID_TYPE } from '../../config/const.config';

const ListEventRegistrations = ({ id }) => {
  const viewColumns = VIEW_COLUMNS;
  const listColumns = LISTING_COLUMNS;

  const [viewData, setViewData] = useState(null);
  const [isRecurring, setIsRecurring] = useState(null);
  const [loading, setLoading] = useState(true);

  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      recurringEventStartDate: '',
    },
  });

  const datesWatch = watch('recurringEventStartDate');
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
  });

  const handleLoading = () => {
    setLoading(false);
  };

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const getEventRegistration = (output = '') => {
    let uri = `video/${id}/event-registrations`;
    uri += `?page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    }
    if (isRecurring) {
      uri += `&isRecurring=${isRecurring}`;
    }
    if (datesWatch) {
      uri += `&recurringId=${datesWatch}`;
    }
    if (output === 'csv') {
      uri += '&output=csv';
    }

    if (output === '') {
      setLoading(true);
    }

    getEventRegistrationByVideoId(uri)
      .then((res) => {
        let serialRow;
        if (output === '') {
          if (res?.data?.rows) {
            serialRow = res.data.rows;
            serialRow = serialRow.map((tr, count) => ({
              ...tr,
              serial: options.page * options.rowsPerPage + count + 1,
            }));
          }

          if (res?.data?.detail) {
            setIsRecurring(res?.data?.detail?.isRecurring);
            setViewData({
              ...res.data.detail,
              totalRegistrations: res.data.totalRows,
              totalJoined: res.data.totalJoin,
              isPaid: PAID_TYPE[`${res.data.detail.isPaid}`],
            });
          }
          setOptions({
            ...options,
            loading: false,
            totalRows: res?.data?.totalRows || 0,
            rows: serialRow || [],
            error: false,
          });
        } else if (output === 'csv') {
          // eslint-disable-next-line no-unused-vars
          const download = new JsFileDownloader(res, 'event_registrations.csv');
        }

        handleLoading();
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  };

  const downloadReport = () => {
    getEventRegistration('csv');
  };

  useEffect(() => {
    if (datesWatch) {
      getEventRegistration();
    }
  }, [options.reloadCounter, datesWatch]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 1 }}
      >
        <Stack direction="row" sx={{ marginBottom: 1 }}>
          <Link to={ROUTE_PATH.CONTENT_VIDEOS}>
            <Button
              color="inherit"
              variant="contained"
              startIcon={<Iconify icon="ic:arrow-back" />}
              sx={{ marginRight: '5px' }}
            >
              Back
            </Button>
          </Link>

          <Stack>
            <Controller
              name="recurringEventStartDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RecurringEventDate
                  id="recurringEventStartDate"
                  name="recurringEventStartDate"
                  label={isRecurring ? 'Event Recurring Dates' : 'Event Date'}
                  defaultValue={value}
                  onChange={onChange}
                  error={errors?.recurringEventStartDate?.message || ''}
                  options={options}
                  disabled={!Number(isRecurring)}
                  videoId={id}
                  sx={{
                    width: '230px',
                    height: '36px',
                    marginLeft: '10px',
                  }}
                  handleLoading={handleLoading}
                />
              )}
            />
          </Stack>
        </Stack>

        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <div>
        {options.loading && <Loading />}
        {!options.loading && <ViewData columns={viewColumns} row={viewData} />}
        <div style={{ marginBottom: '20px' }} />
      </div>

      <div
        style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px' }}
      >
        <Button variant="contained" onClick={() => downloadReport()}>
          Download CSV
        </Button>
      </div>

      {!options.loading && (
        <Card>
          <ListData
            columns={listColumns}
            rows={options.rows}
            page={options.page}
            rowsPerPage={options.rowsPerPage}
            totalRows={options.totalRows}
            loading={loading}
            actions={[]}
            error={options.error}
            sortBy={options.sortBy}
            sortOrder={options.sortOrder}
            onPageChange={handlePageChange}
            onAction={() => {}}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSortChange={handleSortingChange}
          />
        </Card>
      )}
    </>
  );
};

ListEventRegistrations.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ListEventRegistrations;
