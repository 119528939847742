import React from 'react';
import { Grid } from '@mui/material';
import AppLayout from '../../layout/app.layout';
import WatchedSecondsChart from '../../components/reports/watched-seconds-chart.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ReportsOverviewView = () => {
  const pageTitle = PAGE_TITLE_LIST.REPORTS_OVERVIEW_VIDEOS;

  return (
    <AppLayout pageTitle={pageTitle}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <WatchedSecondsChart />
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default ReportsOverviewView;
