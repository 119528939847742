import React from 'react';
import PropTypes from 'prop-types';
import Iconify from '../common/iconify.component';

const Sortable = ({ dataKey, sortBy, sortOrder }) => {
  if (dataKey === sortBy && sortOrder === 'asc') {
    return <Iconify icon="ic:baseline-arrow-upward" sx={{ marginRight: 1 }} />;
  }
  if (dataKey === sortBy && sortOrder === 'desc') {
    return (
      <Iconify icon="ic:baseline-arrow-downward" sx={{ marginRight: 1 }} />
    );
  }

  return <Iconify icon="ic:round-sort" sx={{ marginRight: 1 }} />;
};

Sortable.propTypes = {
  dataKey: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
};

export default Sortable;
