import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// Layout for empty structure
const EmptyLayout = ({ pageTitle, children }) => {
  const appName = process.env.REACT_APP_NAME;

  return (
    <>
      <Helmet>
        <title>
          {appName} - {pageTitle}
        </title>
      </Helmet>

      {children}
    </>
  );
};

EmptyLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default EmptyLayout;
