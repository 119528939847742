import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import VideoTypes from '../form-elements/video-types.component';
import CommentsConversation from './comments-conversation.component';
import Iconify from '../common/iconify.component';
import {
  getListVideosByType,
  viewVideoById,
} from '../../services/content.service';
import validationRules from '../../utils/validations.util';
import { CONTENT_ALIAS, VIDEO_TYPES } from '../../config/const.config';

const ListVideoComments = () => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: { videoType: '' },
  });
  const { videoID } = useParams();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const videoType = watch('videoType');
  const searchVideosRef = useRef(null);

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });

  const getVideosList = () => {
    let uri = '';
    uri += `?page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.q) {
      uri += `&q=${options.q}`;
    }

    setOptions({ ...options, loading: true, searchValue: '' });

    getListVideosByType(videoType, uri)
      .then((res) => {
        if (options.page === 0) {
          setOptions({
            ...options,
            loading: false,
            totalRows: res?.data?.totalRows || 0,
            rows: res?.data?.rows || [],
            error: false,
          });
        } else {
          setOptions({
            ...options,
            loading: false,
            totalRows: res?.data?.totalRows || 0,
            rows: [...options.rows, ...res.data.rows] || [],
            error: false,
          });
        }
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  };

  const handleScroll = () => {
    setOptions({
      ...options,
      page: options.page + 1,
      reloadCounter: options.reloadCounter + 1,
    });
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      q: e.target.value || '',
      reloadCounter: options.reloadCounter + 1,
    });
  };

  const handleSearchVideos = () => {
    setOptions({
      ...options,
      q: searchVideosRef.current.value,
      reloadCounter: options.reloadCounter + 1,
      page: 0,
    });
  };

  useEffect(() => {
    setOptions({
      ...options,
      loading: false,
      page: 0,
      totalRows: 0,
      rows: [],
      error: true,
      reloadCounter: options.reloadCounter + 1,
    });
  }, [videoType]);

  useEffect(() => {
    if (videoType) {
      getVideosList();
    }
  }, [options.reloadCounter]);

  useEffect(() => {
    if (videoID) {
      viewVideoById(videoID)
        .then((res) => {
          setValue('videoType', res.data.videoType);
          setSelectedVideo(res.data);
        })
        .catch(() => {});
    }
  }, []);

  return (
    <Stack direction="row" sx={{ width: '100%' }}>
      <Box
        sx={{
          position: 'relative',
          width: 320,
          height: '78vh',
          backgroundColor: '#F8FAFF',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Stack p={2} sx={{ height: '78vh' }}>
          <Controller
            name="videoType"
            control={control}
            rules={validationRules.REQUIRED}
            render={({ field: { onChange, value } }) => (
              <VideoTypes
                id="videoType"
                name="videoType"
                label="Select Video Type"
                defaultValue={value}
                onChange={onChange}
                sx={{ width: '100%' }}
                invalidOptions={[
                  VIDEO_TYPES.LIVE_EVENT.value,
                  VIDEO_TYPES.TESTIMONIALS.value,
                ]}
                error={errors?.videoType?.message || ''}
                searchList={false}
              />
            )}
          />

          <TextField
            label={`Search ${CONTENT_ALIAS.VIDEO_PLURAL}`}
            type="text"
            inputRef={searchVideosRef}
            onChange={handleSearchChange}
            sx={{
              marginY: 1,
              width: '100%',
              '.MuiInputLabel-root.MuiInputLabel-shrink.MuiFormLabel-filled': {
                top: '0px',
              },
              '.MuiInputLabel-root': {
                top: '-8px',
              },
            }}
            InputProps={{
              style: {
                height: '2.25em',
              },
              endAdornment: (
                <InputAdornment position="end" onClick={handleSearchVideos}>
                  <IconButton edge="end">
                    <Iconify icon="fe:search" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: !(
                searchVideosRef.current?.value === '' ||
                searchVideosRef.current === null
              ),
            }}
            disabled={!videoType}
          />

          <List
            sx={{
              height: options.rows < options.totalRows ? '98%' : '100%',
              width: '100%',
              overflowY: 'auto',
              // '::-webkit-scrollbar': {
              //   display: 'none',
              // },
              // '-ms-overflow-style': 'none',
              // 'scrollbar-width': 'none',
              '::-webkit-scrollbar': {
                width: '6px',
              },
              '::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 2px rgba(0, 0, 0, 0)',
                marginY: '12px',
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                borderRadius: '12px',
              },
              ':hover': {
                'overflow-y': 'auto',
              },
              ':active': {
                'overflow-y': 'auto',
              },
              ':focus': {
                'overflow-y': 'auto',
              },
              textAlign: 'center',
            }}
            spacing={1}
          >
            {options.rows.map((el, index) => (
              <ListItem
                key={el.id}
                sx={{
                  marginY: index === 0 || index === options.totalRows ? 0 : 1,
                  cursor: 'pointer',
                  borderRadius: '5px',
                }}
                onClick={() => setSelectedVideo(el)}
              >
                <ListItemAvatar sx={{ marginRight: '10px' }}>
                  {el.thumbnail ? (
                    <Box
                      component="img"
                      sx={{
                        objectFit: 'contain',
                        width: 64,
                        height: 36,
                        bgcolor: '#EDEDED',
                        borderRadius: '5px',
                      }}
                      alt={el.title}
                      src={el.thumbnail}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: 64,
                        height: 36,
                        bgcolor: '#808080',
                        borderRadius: '5px',
                      }}
                    />
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                        fontSize: '14px',
                      }}
                    >
                      {el.title}
                    </Typography>
                  }
                />
              </ListItem>
            ))}

            {options.rows.length < options.totalRows && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleScroll()}
                sx={{
                  lineHeight: 0.5,
                  fontSize: '12px',
                  marginTop: '15px',
                  width: 'fit-content',
                }}
              >
                Load More
              </Button>
            )}
          </List>
        </Stack>
      </Box>

      <Box
        sx={{
          height: '78vh',
          width: '1150px',
          backgroundColor: '#F8FAFF',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        }}
      >
        {selectedVideo && (
          <CommentsConversation selectedVideo={selectedVideo} />
        )}
      </Box>
    </Stack>
  );
};

export default ListVideoComments;
