import React from 'react';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListPackages from '../../components/packages/list-packages.component';

const PackagesView = () => {
  const pageTitle = PAGE_TITLE_LIST.PACKAGES;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListPackages />
    </AppLayout>
  );
};

export default PackagesView;
