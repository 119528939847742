import React from 'react';
import AppLayout from '../../layout/app.layout';
import Notifications from '../../components/settings/notification.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const NotificationsView = () => {
  const pageTitle = PAGE_TITLE_LIST.NOTIFICATIONS;

  return (
    <AppLayout pageTitle={pageTitle}>
      <Notifications />
    </AppLayout>
  );
};

export default NotificationsView;
