/* eslint-disable no-unused-vars */
import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  updateUserTestimonialVideoById,
  viewUserTestimonialVideoById,
} from '../../services/content.service';
import TestimonialCategories from '../form-elements/testimonial-category.component';
import TestimonialTabs from '../form-elements/testimonial-tab.component';
import ImageUpload from './image-upload.component';
import { validateSelectedImageFile } from '../../utils/file-validations.util';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const EditUserTestimonialVideo = ({
  title,
  dataId,
  options,
  onCancel,
  onSuccess,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      status: null,
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [videoData, setVideoData] = useState({
    thumbnail: '',
  });

  const [renderImage, setRenderImage] = useState(false);
  const [fileData, setFileData] = useState({
    selectedFile: null,
    fileName: '',
  });
  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const updateExistingData = (payload) => {
    updateUserTestimonialVideoById(dataId, payload)
      .then(() => {
        onSuccess('Video Testimonial details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const showToastMsg = (msgType, message) => {
    if (msgType === 'error') {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }

    if (msgType === 'success') {
      setSnackbarInfo({
        show: true,
        type: 'success',
        message,
      });
    }
  };

  const showUpdatedImage = (rerender) => {
    if (rerender) {
      setLoading(true);
    }
    setRenderImage(rerender);
  };

  const updateData = (data) => {
    const imageFormData = new FormData();

    imageFormData.append(
      'file',
      fileData.selectedFile ? fileData.selectedFile[0] : ''
    );
    imageFormData.append('imageType', 'thumbnail');
    imageFormData.append('selectedImageFileName', fileData.fileName || '');
    imageFormData.append('title', data.title);
    imageFormData.append('description', data.description);
    imageFormData.append('status', data.status);
    imageFormData.append('testimonialBy', data.testimonialBy);
    imageFormData.append('testimonialTabID', data.testimonialTabID);
    imageFormData.append('testimonialCategoryID', data.testimonialCategoryID);
    imageFormData.append('videoUrl', data.videoUrl);
    imageFormData.append('duration', data.duration);
    imageFormData.append('thumbnail', data.thumbnail);

    if (dataId) {
      updateExistingData(imageFormData);
    }
  };

  const onFormSubmit = async (data) => {
    let validationError = '';
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    await sleep(100);

    // const imageFormData = new FormData();
    if (!data.thumbnail || (data.thumbnail && fileData.selectedFile)) {
      validationError = validateSelectedImageFile(fileData.selectedFile);
      if (validationError) {
        showToastMsg('error', validationError);
        setFormSubmitted(false);
        setLoading(false);
      } else {
        updateData(data);
      }
    } else {
      updateData(data);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewUserTestimonialVideoById(dataId)
      .then((res) => {
        // Fill form values
        setValue('title', res.data?.title || '');
        setValue('description', res.data?.description || '');
        setValue('status', res.data?.status || 0);
        setValue('testimonialBy', res.data?.testimonialBy || '');
        setValue('videoUrl', res.data?.videoUrl || '');
        setValue('duration', res.data?.duration || 0);
        setValue('thumbnail', res.data?.thumbnail || '');

        setVideoData({
          ...videoData,
          thumbnail: res.data?.thumbnail || '',
        });

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Testimonial Video Title"
                      type="text"
                      error={!!errors.title}
                      helperText={errors?.title?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(1500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      type="text"
                      error={!!errors.description}
                      helperText={errors?.description?.message || null}
                      fullWidth
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 1500 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="testimonialTabID"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <TestimonialTabs
                      id="testimonialTabID"
                      name="testimonialTabID"
                      label="Select Testimonial Tab"
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.testimonialTabID?.message || ''}
                      options={options}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="testimonialCategoryID"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <TestimonialCategories
                      id="testimonialCategoryID"
                      name="testimonialCategoryID"
                      label="Select Testimonial Category"
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.testimonialCategoryID?.message || ''}
                      options={options}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="testimonialBy"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Testimonial By"
                      type="text"
                      error={!!errors.testimonialBy}
                      helperText={errors?.testimonialBy?.message || null}
                      fullWidth
                      disabled
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="videoUrl"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Video Url"
                      type="text"
                      error={!!errors.videoUrl}
                      helperText={errors?.videoUrl?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 500 }}
                      disabled
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="duration"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Duration (In Seconds)"
                      type="number"
                      error={!!errors.duration}
                      helperText={errors?.duration?.message || null}
                      fullWidth
                      disabled
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="thumbnail"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(250) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Thumbnail Url"
                      type="text"
                      error={!!errors.thumbnail}
                      helperText={errors?.thumbnail?.message || null}
                      fullWidth
                      disabled
                      inputProps={{ maxLength: 250 }}
                    />
                  )}
                />
              </Grid>

              <Box
                component="section"
                sx={{
                  p: 3,
                  border: '1px solid lightgrey',
                  borderRadius: '5px',
                  width: '100%',
                  marginTop: 3,
                }}
              >
                <ImageUpload
                  title="Thumbnail"
                  dataId={dataId}
                  showToastMsg={showToastMsg}
                  setSnackbarInfo={setSnackbarInfo}
                  videoData={videoData}
                  loading={loading}
                  showUpdatedImage={showUpdatedImage}
                  uploadFrom="form"
                  setFileData={setFileData}
                  contentType="user-testimonial"
                />
              </Box>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Approve
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

EditUserTestimonialVideo.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  options: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

EditUserTestimonialVideo.defaultProps = {
  options: {},
};

export default EditUserTestimonialVideo;
