import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DialogContent, DialogActions, Button, Grid, Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Dropzone from 'react-dropzone';
import {
  getVideoFileUploadHeader,
  startVideoFileUpload,
} from '../../utils/bunny-cdn.util';
import { validateSelectedVideoFile } from '../../utils/file-validations.util';
import Player from '../common/player.component';
import { getPlayableStream } from '../../services/content.service';
import { VIDEO_TYPES } from '../../config/const.config';

const VideoUpload = ({
  dataId,
  dataTitle,
  showToastMsg,
  videoData,
  setSnackbarInfo,
  type,
}) => {
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFilesObj, setSelectedFilesObj] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const progressBar = (progressPercentage) => {
    setProgress(progressPercentage);
  };

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSelectFiles = async (files) => {
    setSelectedFilesObj(files);
    setProgress(0);
    setSnackbarInfo({
      show: false,
    });
    await sleep(100);

    const validationError = validateSelectedVideoFile(files);
    if (validationError) {
      showToastMsg('error', validationError);
    }

    // Show file name below button
    for (let i = 0; i < files.length; i += 1) {
      setSelectedFileName(files[i].name);
    }
  };

  const handleUploadFiles = async (videoId, videoTitle) => {
    setSnackbarInfo({
      show: false,
    });
    await sleep(100);

    const validationError = validateSelectedVideoFile(selectedFilesObj);
    if (validationError) {
      showToastMsg('error', validationError);
    } else {
      setShowProgressBar(true);
      try {
        const fileUploadHeader = await getVideoFileUploadHeader(
          videoId,
          videoTitle,
          type
        );

        if (fileUploadHeader && fileUploadHeader.guid !== '') {
          startVideoFileUpload(
            selectedFilesObj[0],
            fileUploadHeader,
            videoTitle,
            progressBar,
            showToastMsg
          );
        }
      } catch {
        showToastMsg('error', 'Something went wrong! Please try again.');
      }
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const LinearProgressWithLabel = (props) => {
    const { value } = props;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  };

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

  useEffect(() => {
    let uri = '';
    if (type === VIDEO_TYPES.TESTIMONIALS.value) {
      uri += `videoID=${dataId}&type=testimonial`;
    } else {
      uri += `videoID=${dataId}`;
    }
    uri += `&upload=true`;

    getPlayableStream(uri)
      .then((res) => {
        setVideoUrl(res.data?.videoUrl || '');
      })
      .catch(() =>
        setTimeout(() => {
          setVideoUrl('');
        }, 5000)
      );
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{
          whiteSpace: 'unset',
          wordBreak: 'break-all',
        }}
      >
        <Grid item sx={{ fontWeight: 'bold' }}>
          Video Preview:
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <DialogContent sx={{ padding: '0px 0px 10px 0px' }}>
            {videoUrl && (
              <Player
                videoUrl={videoUrl}
                height="180px"
                width="320px"
                autoplay={false}
              />
            )}
          </DialogContent>
        </Grid>
        <Grid item>
          <Box sx={{ fontWeight: 'bold' }}>Video URL:&nbsp;</Box>
          <Box sx={{ fontSize: '15px' }}>{videoData.videoUrl}</Box>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={8} md={8}>
        <Grid item xs={12} sm={12} md={12}>
          <Dropzone
            onDrop={(acceptedFiles) => handleSelectFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />

                  <Box
                    component="section"
                    sx={{
                      p: 3,
                      border: '1px dashed grey',
                      borderRadius: '20px',
                      width: '100%',
                      marginTop: 3,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        Add/Replace video file
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          Drag and drop video files here, or click to browse
                        </Grid>

                        <DialogActions sx={{ justifyContent: 'center' }}>
                          <Button
                            component="label"
                            variant="contained"
                            sx={{
                              backgroundColor: '#808080',
                            }}
                          >
                            Select Video File
                          </Button>
                        </DialogActions>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {`${selectedFileName}`}
                    </Grid>

                    {showProgressBar && (
                      <Box sx={{ width: '100%' }} id="progress-bar">
                        <LinearProgressWithLabel value={progress} />
                      </Box>
                    )}
                  </Box>
                </div>
              </section>
            )}
          </Dropzone>
        </Grid>

        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            color="primary"
            variant="contained"
            disabled={progress > 0}
            onClick={() => handleUploadFiles(dataId, dataTitle)}
          >
            Upload Video
          </Button>
        </DialogActions>

        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 2,
          }}
        >
          <Box sx={{ fontWeight: 'bold' }}>Note:&nbsp;</Box>
          It might take time to play/preview as per uploaded video duration and
          size.
        </Grid>

        <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
          Do not close or refresh browser while video is uploading.
        </Grid>
      </Grid>
    </Grid>
  );
};

VideoUpload.propTypes = {
  dataId: PropTypes.number.isRequired,
  dataTitle: PropTypes.string.isRequired,
  showToastMsg: PropTypes.func.isRequired,
  videoData: PropTypes.object.isRequired,
  setSnackbarInfo: PropTypes.object.isRequired,
  type: PropTypes.string,
};

VideoUpload.defaultProps = {
  type: '',
};

export default VideoUpload;
