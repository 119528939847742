import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Stack } from '@mui/material';
import Iconify from '../common/iconify.component';
import ListData from '../table-elements/list-data.component';
import {
  LISTING_ACTIONS,
  LISTING_COLUMNS,
} from '../../config/module-configs/sections.config';
import { deleteSectionById, getDataList } from '../../services/content.service';
import { ACTIONS, CONTENT_ALIAS } from '../../config/const.config';
import SnackbarInfo from '../common/snackbar-info.component';
import ImagePreview from './image-preview.component';
import PosterUpload from './poster-upload.component';
import ConfirmPopup from '../common/confirm-popup.component';
import TranslateSections from './translate-sections.component';
import AddEditSections from './add-edit-sections.component';
import ViewSection from './view-section.component';
import SearchList from '../table-elements/search-list.component';

const ListSections = () => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const searchCategoriesRef = useRef(null);
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [imagePreview, setImagePreview] = useState({
    show: false,
    imageUrl: '',
    title: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });

    setImagePreview({
      ...imagePreview,
      show: false,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleImagePreview = (rowData) => {
    setImagePreview({
      ...imagePreview,
      show: true,
      imageUrl: rowData.poster,
      title: rowData.title,
    });
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      searchValue: e.target.value || '',
    });
  };

  const handleSearchSections = () => {
    if (options.loading) return;
    setOptions({
      ...options,
      q: searchCategoriesRef.current.value,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
      page: 0,
    });
  };

  const handleClearSearch = () => {
    if (options.loading) return;
    setOptions({
      ...options,
      searchValue: '',
      q: null,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
    searchCategoriesRef.current.value = '';
  };

  const dataListAPICall = (newOptions = {}) => {
    let uri = 'section';
    uri += `?page=${newOptions?.page || options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    } else if (searchCategoriesRef.current?.value) {
      uri += `&q=${searchCategoriesRef.current.value}`;
    }

    setOptions({ ...options, loading: true, searchValue: '' });

    getDataList(uri)
      .then((res) => {
        let serialRow;

        if (res?.data?.rows) {
          serialRow = res.data.rows;
          serialRow = serialRow.map((tr, count) => ({
            ...tr,
            serial: options.page * options.rowsPerPage + count + 1,
          }));
        }

        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: serialRow || [],
          q: null,
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          q: null,
          error: true,
        });
      });
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    deleteSectionById(doAction.data.id)
      .then(() => {
        handleActionSuccess(
          `${CONTENT_ALIAS.SECTION_SINGULAR} deleted successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  useEffect(() => {
    const newOptions = options;
    newOptions.page = 0;
    setOptions({
      ...options,
      page: 0,
    });

    if (searchCategoriesRef.current?.value) {
      searchCategoriesRef.current.value = null;
    }

    dataListAPICall(newOptions);
  }, []);

  useEffect(() => {
    if (options.reloadCounter > 0) {
      dataListAPICall();
    }
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add" />}
            onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
          >
            Create New
          </Button>

          <SearchList
            label={CONTENT_ALIAS.SECTION_PLURAL}
            handleSearch={handleSearchSections}
            inputRef={searchCategoriesRef}
            onChange={handleSearchChange}
            options={options}
            clearSearch={handleClearSearch}
            loading={options.loading}
            width="285px"
          />
        </div>
        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
          handleImagePreview={handleImagePreview}
        />
      </Card>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditSections
          title={`Add New ${CONTENT_ALIAS.SECTION_SINGULAR}`}
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditSections
          title={`Edit ${CONTENT_ALIAS.SECTION_SINGULAR} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.TRANSLATE.value && (
        <TranslateSections
          title={`Translate ${CONTENT_ALIAS.SECTION_SINGULAR} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          allowTranslatePoster={doAction.data.parentTitle}
        />
      )}

      {doAction.action === ACTIONS.UPLOAD_POSTER.value && (
        <PosterUpload
          title={`${CONTENT_ALIAS.SECTION_SINGULAR}  - Upload Poster - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          imageUrl={doAction.data.poster}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewSection
          title={`${CONTENT_ALIAS.SECTION_SINGULAR} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}
      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title="Delete Section Category"
          message={`Do you want to delete '${doAction.data.title}' Section Category? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {imagePreview.show && (
        <ImagePreview
          title={`Image - ${imagePreview.title}`}
          onCancel={handleActionCancel}
          imageUrl={imagePreview.imageUrl}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ListSections.propTypes = {};

export default ListSections;
