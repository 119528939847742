import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListVideoComments from '../../components/content/list-video-comments.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentVideoCommentsView = () => {
  const pageTitle = PAGE_TITLE_LIST.CONTENT_VIDEO_COMMENTS;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListVideoComments />
    </AppLayout>
  );
};

ContentVideoCommentsView.propTypes = {};

export default ContentVideoCommentsView;
