import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListTestimonialText from '../../components/content/list-testimonial-text.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentTextView = () => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_TESTIMONIALS_TEXT}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListTestimonialText />
    </AppLayout>
  );
};

ContentTextView.propTypes = {};

export default ContentTextView;
