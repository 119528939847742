import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, LANGUAGES } from '../const.config';

// Categories module configurations
export const LISTING_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    width: '20%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'articleType',
    label: 'Article Type',
    width: '10%',
    align: 'center',
    dataKey: 'articleType',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'articleCategory',
    label: 'Article Category',
    width: '10%',
    align: 'center',
    dataKey: 'articleCategory',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  // {
  //   id: 'displaySequence',
  //   label: `Display Sequence`,
  //   width: '20%',
  //   align: 'center',
  //   dataKey: 'displaySequence',
  //   sortable: true,
  //   type: CELL_DATA_TYPES.TEXT,
  // },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.UPLOAD_POSTER.value,
    label: ACTIONS.UPLOAD_POSTER.label,
  },
];

for (let i = 0; i < LANGUAGES.length; i += 1) {
  LISTING_ACTIONS.push({
    action: LANGUAGES[i].value,
    label: `Translate ${LANGUAGES[i].label}`,
  });
}

LISTING_ACTIONS.push({
  action: ACTIONS.DELETE.value,
  label: ACTIONS.DELETE.label,
});

export const VIEW_ARTICLE_CONTENT_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'articleType',
    label: 'Article Type',
    dataKey: 'articleType',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'articleCategory',
    label: 'Article Category',
    dataKey: 'articleCategory',
    type: CELL_DATA_TYPES.TEXT,
  },
  // {
  //   id: 'displaySequence',
  //   label: 'Display Sequence',
  //   dataKey: 'displaySequence',
  //   type: CELL_DATA_TYPES.TEXT,
  // },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'content',
    label: 'Content',
    dataKey: 'content',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'banner',
    label: 'Banner',
    dataKey: 'banner',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
