import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { STATUS_LABELS, STATUS_COLORS } from '../../config/const.config';

const StatusLabel = ({ value }) => {
  const label = STATUS_LABELS[value] || 'Unknown';
  const color = STATUS_COLORS[value] || 'primary';

  return <Chip label={label} color={color} variant="filled" size="small" />;
};

StatusLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default StatusLabel;
