import { LS_ADMIN_USER_AUTH_TOKEN } from '../config/const.config';
import { getDeviceId } from './device-info.util';
import { getLocalValue } from './local-cache.util';

// Get option headers for admin API calls
const prepareRequestOptions = (url, method, data = null, auth = false) => {
  const baseAPIUrl = process.env.REACT_APP_CMS_API_ENDPOINT;
  const uuid = getDeviceId();

  const options = {
    url: `${baseAPIUrl}${url}`,
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-Req-Device': uuid,
    },
    data: JSON.stringify(data || {}),
  };
  if (auth) {
    const adminAuth = getLocalValue(LS_ADMIN_USER_AUTH_TOKEN);
    options.headers['X-Admin-Auth-Token'] = adminAuth;
  }

  return options;
};

export default prepareRequestOptions;
