import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST, { SEPERATOR } from '../../config/page-title.config';
import ListManageMembers from '../../components/groups/list-manage-members.component';

const ManageMembersView = () => {
  const { id } = useParams();
  const location = useLocation();

  const pageTitle = `${PAGE_TITLE_LIST.MANAGE_MEMBERS}${
    location?.state?.name ? ` ${SEPERATOR} ${location.state.name}` : ''
  }`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListManageMembers conversationId={id} />
    </AppLayout>
  );
};

export default ManageMembersView;
