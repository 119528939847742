import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes, Navigate } from 'react-router-dom';
import ROUTE_PATH from '../config/routes.config';
import LoginView from '../views/auth/login.view';
import DashboardView from '../views/dashboard/dashboard.view';
import RealtimeDashboardView from '../views/dashboard/realtime-dashboard.view';
import ContentVideosView from '../views/content/videos.view';
import PopularContentView from '../views/content/popular-content.view';
import ContentSequenceView from '../views/content/content-sequence.view';
import SubscribersView from '../views/subscribers/subscribers.view';
import SubscriptionsView from '../views/subscribers/subscriptions.view';
import AdminUsersView from '../views/admin-users/admin-users.view';
import ReportsOverviewView from '../views/reports/overview.view';
import ReportsLiveUsersView from '../views/reports/live-users.view';
import ReportsSessionsView from '../views/reports/sessions.view';
import ReportsSubscriptionsView from '../views/reports/subscriptions-summary.view';
import PageNotFoundView from '../views/errors/not-found.view';
import LogoutView from '../views/auth/logout.view';
import NotificationsView from '../views/settings/notification.view';
import SalutationView from '../views/settings/salutation.view';
import ContentCategoriesView from '../views/content/categories.view';
import ContentTestimonialTabsView from '../views/content/testimonial-tabs.view';
import ContentTestimonialCategoriesView from '../views/content/testimonial-categories.view';
import ContentTestimonialTextView from '../views/content/testimonial-text.view';
import ContentUserTestimonialTextView from '../views/content/user-testimonial-text.view';
import ContentUserTestimonialVideoView from '../views/content/user-testimonial-video.view';
import ContentVideoCommentsView from '../views/content/video-comments.view';
import {
  SHOW_ADMIN_USERS,
  SHOW_CONTENT_SECTION,
  SHOW_CONTENT_CATEGORIES,
  SHOW_CONTENT_SEQUENCE,
  SHOW_CONTENT_VIDEOS,
  SHOW_NOTIFICATIONS,
  SHOW_OVERVIEW_DASHBOARD,
  SHOW_REALTIME_DASHBOARD,
  SHOW_REPORTS_LIVE_USERS,
  SHOW_REPORTS_OVERVIEW_VIDEOS,
  SHOW_REPORTS_SUBSCRIPTIONS,
  SHOW_REPORTS_WATCH_SESSIONS,
  SHOW_SALUTATION,
  SHOW_SUBSCRIBERS,
  SHOW_SUBSCRIPTIONS,
  SHOW_TESTIMONIALS,
  SHOW_VIDEO_COMMENTS,
  SHOW_GROUPS,
  SHOW_CONTENT_COMMUNITY_GATHERINGS,
  SHOW_PACKAGES,
  SHOW_CONTENT_POPULAR,
  SHOW_ARTICLE,
  VIDEO_TYPES,
  CATEGORY_TYPE,
  CONTENT_ALIAS,
} from '../config/const.config';
import GroupsView from '../views/groups/groups.view';
import PackagesView from '../views/packages/packages.view';
import ContentSectionsView from '../views/content/sections.view';
import ContentCommunityGatheringView from '../views/content/community-gathering.view';
import ArticleContent from '../views/content/article-content.view';
import ArticleCategories from '../views/content/article-categories.view';
import VideoSequenceView from '../views/content/video-sequence.view';
import ManageMembersView from '../views/groups/manage-members.view';
import EventRegistrationsView from '../views/content/event-registrations.view';

const AppRouter = ({ isLoggedIn }) => {
  const routeMappings = [];

  if (isLoggedIn) {
    routeMappings.push({
      path: ROUTE_PATH.LOGIN,
      element: <Navigate to={ROUTE_PATH.DASHBOARD} />,
      exact: true,
    });

    if (SHOW_OVERVIEW_DASHBOARD) {
      routeMappings.push({
        path: ROUTE_PATH.DASHBOARD,
        element: <DashboardView />,
        exact: true,
      });
    }

    if (SHOW_REALTIME_DASHBOARD) {
      routeMappings.push({
        path: ROUTE_PATH.REALTIME_DASHBOARD,
        element: <RealtimeDashboardView />,
        exact: true,
      });
    }

    if (SHOW_SUBSCRIBERS) {
      routeMappings.push({
        path: ROUTE_PATH.SUBSCRIBERS,
        element: <SubscribersView />,
        exact: true,
      });
    }

    if (SHOW_SUBSCRIPTIONS) {
      routeMappings.push({
        path: ROUTE_PATH.SUBSCRIPTIONS,
        element: <SubscriptionsView />,
        exact: true,
      });
    }

    if (SHOW_PACKAGES) {
      routeMappings.push({
        path: ROUTE_PATH.PACKAGES,
        element: <PackagesView />,
        exact: true,
      });
    }

    if (SHOW_GROUPS) {
      routeMappings.push({
        path: ROUTE_PATH.GROUPS,
        element: <GroupsView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.GROUPS_MANAGE_MEMBERS,
        element: <ManageMembersView />,
        exact: true,
      });
    }

    if (SHOW_ADMIN_USERS) {
      routeMappings.push({
        path: ROUTE_PATH.ADMIN_USERS,
        element: <AdminUsersView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_SECTION) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_SECTIONS,
        element: <ContentSectionsView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_CATEGORIES) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_CATEGORIES,
        element: <ContentCategoriesView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_VIDEOS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS,
        element: <ContentVideosView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS_EVENT_REGISTRATIONS,
        element: <EventRegistrationsView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_POPULAR) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_POPULAR,
        element: <PopularContentView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_COMMUNITY_GATHERINGS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_COMMUNITY_GATHERINGS,
        element: <ContentCommunityGatheringView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_SEQUENCE) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_SEQUENCE_COURSE,
        element: <ContentSequenceView type={CATEGORY_TYPE[0].id} />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_SEQUENCE_PROGRAM,
        element: <ContentSequenceView type={CATEGORY_TYPE[1].id} />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_SEQUENCE_TAO_SONG,
        element: <VideoSequenceView type={VIDEO_TYPES.TAO_SONG.value} />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_SEQUENCE_BLESSING,
        element: <VideoSequenceView type={VIDEO_TYPES.BLESSINGS.value} />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_SEQUENCE_MEDITATION,
        element: <VideoSequenceView type={VIDEO_TYPES.MEDITATION.value} />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_SEQUENCE_ARTICLE_CONTENT,
        element: (
          <ContentSequenceView type={CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL} />
        ),
        exact: true,
      });
    }

    if (SHOW_ARTICLE) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_ARTICLE_CONTENTS,
        element: <ArticleContent />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_ARTICLE_CATEGORIES,
        element: <ArticleCategories />,
        exact: true,
      });
    }

    if (SHOW_TESTIMONIALS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_TESTIMONIALS_TABS,
        element: <ContentTestimonialTabsView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_TESTIMONIALS_CATEGORIES,
        element: <ContentTestimonialCategoriesView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_TESTIMONIALS_TEXT,
        element: <ContentTestimonialTextView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_USER_TESTIMONIALS_TEXT,
        element: <ContentUserTestimonialTextView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_USER_TESTIMONIALS_VIDEO,
        element: <ContentUserTestimonialVideoView />,
        exact: true,
      });
    }

    if (SHOW_VIDEO_COMMENTS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEO_COMMENTS,
        element: <ContentVideoCommentsView />,
        exact: true,
      });

      routeMappings.push({
        path: `${ROUTE_PATH.CONTENT_VIDEO_COMMENTS}/:videoID`,
        element: <ContentVideoCommentsView />,
        exact: true,
      });
    }

    if (SHOW_NOTIFICATIONS) {
      routeMappings.push({
        path: ROUTE_PATH.NOTIFICATIONS,
        element: <NotificationsView />,
        exact: true,
      });
    }

    if (SHOW_SALUTATION) {
      routeMappings.push({
        path: ROUTE_PATH.SALUTATION,
        element: <SalutationView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_OVERVIEW_VIDEOS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_OVERVIEW_VIDEOS,
        element: <ReportsOverviewView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_LIVE_USERS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_LIVE_USERS,
        element: <ReportsLiveUsersView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_WATCH_SESSIONS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_SESSIONS,
        element: <ReportsSessionsView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_SUBSCRIPTIONS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_SUBSCRIPTIONS,
        element: <ReportsSubscriptionsView />,
        exact: true,
      });
    }

    routeMappings.push({
      path: ROUTE_PATH.LOGOUT,
      element: <LogoutView />,
      exact: true,
    });
  }

  routeMappings.push({
    path: '*',
    element: isLoggedIn ? <PageNotFoundView /> : <LoginView />,
  });

  const routes = useRoutes(routeMappings);

  return routes;
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default AppRouter;
