import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// module configurations
export const LISTING_COLUMNS = [
  {
    id: 'bannerImageUrl',
    label: 'Image Preview',
    width: '10%',
    align: 'left',
    dataKey: 'bannerImageUrl',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
  },
  {
    id: 'title',
    label: 'Title',
    width: '30%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'gatheringStartDateTime',
    label: 'Gathering Start Date',
    width: '15%',
    align: 'right',
    dataKey: 'gatheringStartDateTime',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'gatheringDuration',
    label: 'Gathering Duration',
    width: '15%',
    align: 'right',
    dataKey: 'gatheringDuration',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.TRANSLATE.value,
    label: ACTIONS.TRANSLATE.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_COMMUNITY_GATHERING_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'bannerImageUrl',
    label: 'Banner URL',
    dataKey: 'bannerImageUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'zoomLink',
    label: 'Zoom Link URL',
    dataKey: 'zoomLink',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'zoomPassword',
    label: 'Zoom Password',
    dataKey: 'zoomPassword',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'gatheringStartDateTime',
    label: 'Gathering Start Date',
    dataKey: 'gatheringStartDateTime',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'gatheringDuration',
    label: 'Gathering Duration (in minutes)',
    dataKey: 'gatheringDuration',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
