import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

export const LISTING_COLUMNS = [
  {
    id: 'code',
    label: 'Code',
    width: '20%',
    align: 'left',
    dataKey: 'code',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'label',
    label: 'Label',
    width: '20%',
    align: 'left',
    dataKey: 'label',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Date Added',
    width: '35%',
    align: 'left',
    dataKey: 'createdAt',
    sortable: false,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];
