import { v4 as uuidv4 } from 'uuid';

export const getDeviceId = () => uuidv4();

export const getDeviceModel = () => {
  const browserList = [
    {
      name: 'Firefox',
      value: 'Firefox',
    },
    {
      name: 'Opera',
      value: 'OPR',
    },
    {
      name: 'Edge',
      value: 'Edg',
    },
    {
      name: 'Chrome',
      value: 'Chrome',
    },
    {
      name: 'Safari',
      value: 'Safari',
    },
  ];

  const { userAgent } = navigator;
  let browser = 'Unknown';

  browserList.forEach((b) => {
    if (userAgent.includes(b.value) && browser === 'Unknown') {
      browser = b.name;
    }
  });

  return browser;
};

export const getUserAgent = () => window.navigator.userAgent.replace(/ /g, '');

export const getDeviceDimensions = () => ({
  width: window.screen.width || 0,
  height: window.screen.height || 0,
});

export const getDeviceOs = () => {
  const osList = [
    {
      name: 'Android',
      value: 'Android',
    },
    {
      name: 'iPhone',
      value: 'iPhone',
    },
    {
      name: 'iPad',
      value: 'Mac',
    },
    {
      name: 'Macintosh',
      value: 'Mac',
    },
    {
      name: 'Linux',
      value: 'Linux',
    },
    {
      name: 'Windows',
      value: 'Win',
    },
  ];

  const { userAgent } = navigator;
  let os = 'Unknown';

  osList.forEach((o) => {
    if (userAgent.includes(o.value) && os === 'Unknown') {
      os = o.name;
    }
  });

  return os;
};

export const getDeviceOsVersion = () => '';

export const getAllDeviceInfo = () => ({
  uid: getDeviceId(),
  model: getDeviceModel(),
  ua: getUserAgent(),
  dimensions: getDeviceDimensions(),
  os: getDeviceOs(),
  osv: getDeviceOsVersion(),
});
