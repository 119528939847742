import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Admin users module configurations
export const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    width: '15%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    width: '20%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'username',
    label: 'Username',
    width: '20%',
    align: 'left',
    dataKey: 'username',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'signupTime',
    label: 'Signup Date',
    width: '15%',
    align: 'left',
    dataKey: 'signupTime',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'signupFromPlatform',
    label: 'Signup From',
    width: '10%',
    align: 'left',
    dataKey: 'signupFromPlatform',
    sortable: true,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'totalSessions',
    label: 'Total Sessions',
    width: '10%',
    align: 'right',
    dataKey: 'totalSessions',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.CHANGE_PASSWORD.value,
    label: ACTIONS.CHANGE_PASSWORD.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    dataKey: 'email',
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'username',
    label: 'Username',
    dataKey: 'username',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'signupTime',
    label: 'Signup Date',
    dataKey: 'signupTime',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'signupFromPlatform',
    label: 'Signup From',
    dataKey: 'signupFromPlatform',
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'notes',
    label: 'Notes',
    dataKey: 'notes',
    type: CELL_DATA_TYPES.TEXT,
  },
];
