import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListVideos from '../../components/content/list-videos.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentVideosView = () => (
  <AppLayout pageTitle={PAGE_TITLE_LIST.CONTENT_VIDEOS}>
    <ListVideos />
  </AppLayout>
);

ContentVideosView.propTypes = {};

export default ContentVideosView;
