import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Container,
  Paper,
  Stack,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  getListSequence,
  updateVideosSequence,
} from '../../services/content.service';
import Iconify from '../common/iconify.component';
import { CONTENT_ALIAS, VIDEO_TYPES } from '../../config/const.config';
import Loading from '../table-elements/loading.component';

const ListVideoSequence = ({ type }) => {
  const [videosList, setVideosList] = useState([]);
  const [vidOptions, setVidOptions] = useState({
    error: false,
    loading: true,
  });

  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const getContentType = () => {
    let label = '';
    if (type === VIDEO_TYPES.TAO_SONG.value) {
      label = CONTENT_ALIAS.TAOSONG_PLURAL;
    } else if (type === VIDEO_TYPES.BLESSINGS.value) {
      label = CONTENT_ALIAS.BLESSING_PLURAL;
    } else if (type === VIDEO_TYPES.MEDITATION.value) {
      label = CONTENT_ALIAS.MEDITATION_PLURAL;
    }

    return label;
  };

  const handleOnDragEndVideos = (result) => {
    if (result.destination) {
      const newVideos = Array.from(videosList);
      const [draggedItem] = newVideos.splice(result.source.index, 1);

      newVideos.splice(result.destination.index, 0, draggedItem);
      setVideosList(newVideos);
    }
  };

  const handleUpdateVideoSequence = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const updatedVideosSequence = videosList.map((video) => video.id);
    const payload = {
      newSequence: updatedVideosSequence,
    };
    updateVideosSequence(payload)
      .then((res) => {
        if (res?.data?.success) {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: `${getContentType()} sequence updated successfully.`,
          });
        }
      })
      .catch(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: 'Something went wrong.',
        });
      });
  };

  const getSequenceData = () => {
    const uri = `videos?type=${type}`;

    getListSequence(uri)
      .then((res) => {
        setVidOptions({
          ...vidOptions,
          loading: false,
          error: false,
        });
        setVideosList(res.data);
      })
      .catch(() => {
        setVidOptions({
          ...vidOptions,
          loading: false,
          error: true,
        });
        setVideosList([]);
      });
  };

  useEffect(() => {
    getSequenceData();
  }, [type]);

  return (
    <>
      {vidOptions.loading && <Loading />}

      {!vidOptions.channelIDError && !vidOptions.loading && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ px: 3, paddingBottom: 2 }}
          >
            <div>&nbsp;</div>
            <div>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Iconify icon="prime:save" />}
                onClick={() => handleUpdateVideoSequence()}
                disabled={videosList.length === 0 || videosList.length === 1}
              >
                Save
              </Button>
            </div>
          </Stack>

          <Container
            maxWidth="false"
            sx={{ maxHeight: '74vh', overflowY: 'auto', paddingX: 0 }}
          >
            <DragDropContext onDragEnd={handleOnDragEndVideos}>
              <Droppable droppableId="videos" isDropDisabled={false}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {!vidOptions.loading &&
                      videosList.length > 0 &&
                      videosList.map((video, index) => (
                        <Draggable
                          key={`video-${video.id}`}
                          draggableId={video.id.toString()}
                          index={index}
                        >
                          {(provide) => (
                            <Card
                              sx={{
                                marginBottom: '5px',
                              }}
                              ref={provide.innerRef}
                              {...provide.draggableProps}
                              {...provide.dragHandleProps}
                            >
                              <CardContent
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  paddingY: '12px',
                                  '&:last-child': {
                                    paddingBottom: '12px',
                                  },
                                }}
                              >
                                <div style={{ width: '75%' }}>
                                  {video.title}
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Iconify icon="ic:round-table-rows" />
                                </div>
                              </CardContent>
                            </Card>
                          )}
                        </Draggable>
                      ))}

                    {!vidOptions.loading && videosList.length === 0 && (
                      <Paper
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '10vh',
                        }}
                      >
                        No data available.
                      </Paper>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Container>
        </>
      )}
      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ListVideoSequence.propTypes = { type: PropTypes.string.isRequired };

export default ListVideoSequence;
