import React from 'react';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListArticleContent from '../../components/content/list-article-content.component';

const ArticleContent = () => {
  const pageTitle = PAGE_TITLE_LIST.CONTENT_ARTICLE_CONTENT;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListArticleContent />
    </AppLayout>
  );
};

export default ArticleContent;
