import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Sidebar from '../components/layout/sidebar.component';
import Header from '../components/layout/header.component';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// Layout for all app pages
const AppLayout = ({ pageTitle, children }) => {
  const appName = process.env.REACT_APP_NAME;

  const [open, setOpen] = useState(false);

  return (
    <StyledRoot>
      <Helmet>
        <title>
          {appName} - {pageTitle}
        </title>
      </Helmet>

      <Header pageTitle={pageTitle} onOpenNav={() => setOpen(true)} />

      <Sidebar openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Container maxWidth={false}>{children}</Container>
      </Main>
    </StyledRoot>
  );
};

AppLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default AppLayout;
