import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Stack } from '@mui/material';
import Iconify from '../common/iconify.component';
import ListData from '../table-elements/list-data.component';
import {
  LISTING_ACTIONS,
  LISTING_COLUMNS,
} from '../../config/module-configs/user-testimonial-video.config';
import {
  deleteUserTestimonialVideoById,
  getDataList,
  getPlayableStream,
  syncVideoCDNStatus,
} from '../../services/content.service';
import { ACTIONS, VIDEO_TYPES } from '../../config/const.config';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ImagePreview from './image-preview.component';
import VideoPreview from './videos-preview.component';
import ViewUserTestimonialVideo from './view-user-testimonial-video.component';
import EditUserTestimonialVideo from './edit-user-testimonial-video.component';
import SearchList from '../table-elements/search-list.component';
import UploadVideo from './upload-video.component';

const ListUserTestimonialVideo = () => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const searchTestimonialVideoRef = useRef(null);
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [videoPlayer, setVideoPlayer] = useState({
    show: false,
    videoUrl: '',
    title: '',
  });
  const [imagePreview, setImagePreview] = useState({
    show: false,
    imageUrl: '',
    title: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });

    setImagePreview({
      ...imagePreview,
      show: false,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleFileDownload = (url, filename = '') => {
    const downloadLink = url;
    const downloadFileName = filename || url.substr(url.lastIndexOf('/') + 1);
    const a = document.createElement('a');
    a.href = downloadLink;
    a.download = downloadFileName;
    a.click();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteUserTestimonialVideoById(doAction.data.id)
      .then(() => {
        handleActionSuccess(`User Video Testimonial deleted successfully.`);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleClickEvent = (video, option = '') => {
    const uri = `videoID=${video.id}&type=testimonial`;

    getPlayableStream(uri)
      .then((res) => {
        if (option === 'download') {
          handleFileDownload(res.data.downloadUrl);
        } else {
          setVideoPlayer({
            ...videoPlayer,
            show: true,
            title: video.title,
            videoUrl: res.data.videoUrl,
          });
        }
      })
      .catch(() => {
        setTimeout(() => {
          setVideoPlayer({
            ...videoPlayer,
            show: false,
            title: '',
            videoUrl: '',
          });
        }, 5000);
      });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
    if (selectedAction === ACTIONS.DOWNLOAD.value) {
      handleClickEvent(selectedData, 'download');
    }
  };

  const handleImagePreview = (rowData) => {
    setImagePreview({
      ...imagePreview,
      show: true,
      imageUrl: rowData.thumbnail,
      title: rowData.title,
    });
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      searchValue: e.target.value || '',
    });
  };

  const handleSearchVideos = () => {
    if (options.loading) return;
    setOptions({
      ...options,
      q: searchTestimonialVideoRef.current.value,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
      page: 0,
    });
  };

  const handleClearSearch = () => {
    if (options.loading) return;
    setOptions({
      ...options,
      searchValue: '',
      q: null,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
    searchTestimonialVideoRef.current.value = '';
  };

  const handleSyncCDNStatus = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    syncVideoCDNStatus('testimonial')
      .then((res) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: `${res.data?.updatedRowsCount} data updated.`,
        });
        setOptions({ ...options, reloadCounter: options.reloadCounter + 1 });
      })
      .catch((e) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: e.response?.data?.message,
        });
      });
  };

  const dataListAPICall = (newOptions = {}) => {
    let uri = 'testimonials/user-video-testimonials';
    uri += `?page=${newOptions?.page || options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    } else if (searchTestimonialVideoRef.current?.value) {
      uri += `&q=${searchTestimonialVideoRef.current.value}`;
    }

    setOptions({ ...options, loading: true, searchValue: '' });

    getDataList(uri)
      .then((res) => {
        let serialRow;

        if (res?.data?.rows) {
          serialRow = res.data.rows;
          serialRow = serialRow.map((tr, count) => ({
            ...tr,
            serial: options.page * options.rowsPerPage + count + 1,
          }));
        }

        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: serialRow || [],
          q: null,
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          q: null,
          error: true,
        });
      });
  };

  useEffect(() => {
    const newOptions = options;
    newOptions.page = 0;
    setOptions({
      ...options,
      page: 0,
    });

    if (searchTestimonialVideoRef.current?.value) {
      searchTestimonialVideoRef.current.value = null;
    }

    dataListAPICall(newOptions);
  }, []);

  useEffect(() => {
    if (options.reloadCounter > 0) {
      dataListAPICall();
    }
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <div
            style={{ whiteSpace: 'no-wrap', overflow: 'hidden', width: '100%' }}
          >
            <Button
              color="primary"
              variant="contained"
              startIcon={<Iconify icon="fe:sync" />}
              sx={{
                marginLeft: '5px',
              }}
              onClick={() => handleSyncCDNStatus()}
            >
              Sync CDN Status
            </Button>
          </div>
          <div>
            <SearchList
              label="User Video Testimonials"
              handleSearch={handleSearchVideos}
              inputRef={searchTestimonialVideoRef}
              onChange={handleSearchChange}
              options={options}
              clearSearch={handleClearSearch}
              loading={options.loading}
              width="280px"
            />
          </div>
        </div>
        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onCustomClick={handleClickEvent}
          handleImagePreview={handleImagePreview}
          onAction={handleActionSelect}
        />
      </Card>

      {doAction.action === ACTIONS.APPROVAL.value && (
        <EditUserTestimonialVideo
          title={`Approve User Video Testimonial Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewUserTestimonialVideo
          title={`Video Testimonial Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.UPLOAD_VIDEO.value && (
        <UploadVideo
          title={`Upload Video - ${doAction.data.title}`}
          dataId={doAction.data.id}
          dataTitle={doAction.data.title}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          type={VIDEO_TYPES.TESTIMONIALS.value}
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title="Delete User Video Testimonial"
          message={`Do you want to delete '${doAction.data.title}' User Video Testimonial? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {videoPlayer.show && (
        <VideoPreview
          videoPlayer={videoPlayer}
          setVideoPlayer={setVideoPlayer}
        />
      )}

      {imagePreview.show && (
        <ImagePreview
          title={`Thumbnail - ${imagePreview.title}`}
          onCancel={handleActionCancel}
          imageUrl={imagePreview.imageUrl}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ListUserTestimonialVideo.propTypes = {};

export default ListUserTestimonialVideo;
