import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Grid,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  assignCategoryToVideo,
  viewVideoById,
} from '../../services/content.service';
import Categories from '../form-elements/categories.component';
import {
  CONTENT_ALIAS,
  MULTIPLE_CAT_PER_VIDEO,
} from '../../config/const.config';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AssignCategoryVideos = ({
  title,
  dataId,
  onCancel,
  onSuccess,
  options,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      categories: '',
    },
  });

  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const saveNewData = (payload) => {
    assignCategoryToVideo(dataId, payload)
      .then(() => {
        setLoading(true);
        onSuccess(
          `${CONTENT_ALIAS.VIDEO_SINGULAR} added to ${CONTENT_ALIAS.CATEGORY_SINGULAR} successfully.`
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
        setLoading(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const categoriesIdStrArr = [];
    if (MULTIPLE_CAT_PER_VIDEO) {
      data.categories.forEach((item) => {
        const spiltData = item.split('] -');
        const categoryId = spiltData[0].slice(1);

        categoriesIdStrArr.push(`${categoryId}`);
      });
    } else {
      categoriesIdStrArr.push(data.categories);
    }

    const payload = {
      categories: categoriesIdStrArr,
    };
    if (dataId) {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    setLoading(true);

    if (!dataId) {
      setLoading(false);
      return;
    }

    let catId;
    let catType;
    let catName;
    const categoriesData = [];

    viewVideoById(dataId)
      .then((res) => {
        res.data?.category.forEach((item) => {
          catId = item.categoryId;
          catName = item.categoryName;
          catType = item.tabName;
          categoriesData.push(`[${catId}] - ${catName} (${catType})`);
        });

        setValue('categories', categoriesData || []);
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="categories"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Categories
                      id="categories"
                      name="categories"
                      label={`Select ${CONTENT_ALIAS.CATEGORY_PLURAL}`}
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.categories?.message || ''}
                      options={options}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AssignCategoryVideos.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
};

export default AssignCategoryVideos;
