/* eslint-disable no-unused-vars */
import React, { useEffect, useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  InputAdornment,
  Box,
  FormControl,
} from '@mui/material';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewCommunityGathering,
  viewCommunityGatheringById,
  updateCommunityGatheringById,
} from '../../services/content.service';
import Status from '../form-elements/status.component';
import Datetimepicker from '../form-elements/datetimepicker.component';
import Datepicker from '../form-elements/datepicker.component';
import ImageUpload from './image-upload.component';
import { validateSelectedImageFile } from '../../utils/file-validations.util';
import { FREQUENCY_TYPE } from '../../config/const.config';
import RecurringType from '../form-elements/recurring-type.component';
import FrequencyTypes from '../form-elements/frequency-type.component';
import DayNames from '../form-elements/day-name.component';
import DateList from '../form-elements/date-list.component';
import { getCurrentTimestamp } from '../../utils/datetime.util';
import WeekNumber from '../form-elements/week-number.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditCommunityGathering = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      banner: '',
      zoomLink: '',
      password: '',
      status: null,
      gatheringStartDateTime: null,
      gatheringDuration: null,
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [videoData, setVideoData] = useState({
    thumbnail: '',
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [renderImage, setRenderImage] = useState(false);
  const [fileData, setFileData] = useState({
    selectedFile: null,
    fileName: '',
  });
  const gatheringStartTimeRef = useRef(null);
  const watchRecurring = watch('recurringType');
  const watchFrequency = watch('frequencyType');
  let recurringEndDateTimeGMT = 0;
  let gatheringStartDateTimeGMT = 0;
  const currentDate = dayjs();
  const maxDate = currentDate.add(2, 'year');

  const handleDayChange = (day) => {
    setSelectedDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };
  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleWeekChange = (week) => {
    setSelectedWeeks((prev) =>
      prev.includes(week) ? prev.filter((d) => d !== week) : [...prev, week]
    );
  };

  const saveNewData = (payload) => {
    createNewCommunityGathering(payload)
      .then(() => {
        onSuccess('Community Gathering added successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateCommunityGatheringById(dataId, payload)
      .then(() => {
        onSuccess('Community Gathering details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };
  const showToastMsg = (msgType, message) => {
    if (msgType === 'error') {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }

    if (msgType === 'success') {
      setSnackbarInfo({
        show: true,
        type: 'success',
        message,
      });
    }
  };

  const showUpdatedImage = (rerender) => {
    if (rerender) {
      setLoading(true);
    }
    setRenderImage(rerender);
  };

  const updateData = (data) => {
    const imageFormData = new FormData();

    imageFormData.append(
      'file',
      fileData.selectedFile ? fileData.selectedFile[0] : ''
    );
    imageFormData.append('imageType', 'thumbnail');
    imageFormData.append('selectedImageFileName', fileData.fileName || '');
    imageFormData.append('title', data.title);
    imageFormData.append('description', data.description || '');
    imageFormData.append('status', Number(data.status) || 0);
    imageFormData.append('banner', data.thumbnail || '');
    imageFormData.append('zoomLink', data.zoomLink);
    imageFormData.append('zoomPassword', data.password || '');
    imageFormData.append('gatheringStartDateTime', gatheringStartDateTimeGMT);
    imageFormData.append('gatheringDuration', data.gatheringDuration);
    imageFormData.append(
      'recurringEndDateTime',
      data.recurringType === 1 ? recurringEndDateTimeGMT || null : null
    );
    imageFormData.append('isRecurring', Number(data.recurringType) || 0);
    imageFormData.append(
      'frequencyType',
      data.recurringType === 1 ? data.frequencyType || null : null
    );
    imageFormData.append(
      'recurringDays',
      data.recurringType === 0
        ? null
        : (data.frequencyType === FREQUENCY_TYPE.WEEKLY.value
            ? selectedDays
            : data.monthDays) || null
    );
    imageFormData.append(
      'weekNumbers',
      data.recurringType === 0
        ? null
        : (data.frequencyType === FREQUENCY_TYPE.WEEKLY.value
            ? selectedWeeks
            : null) || null
    );

    if (dataId) {
      updateExistingData(imageFormData);
    } else {
      saveNewData(imageFormData);
    }
  };

  const onFormSubmit = async (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    await sleep(100);

    gatheringStartDateTimeGMT =
      Date.parse(data.gatheringStartDateTime?.$d.toISOString()) / 1000;
    recurringEndDateTimeGMT =
      Date.parse(data.recurringEndDateTime?.$d.toISOString()) / 1000;

    const currentTime = getCurrentTimestamp();
    if (
      gatheringStartDateTimeGMT <= currentTime &&
      gatheringStartDateTimeGMT !== gatheringStartTimeRef.current
    ) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message:
            'Community gathering start date must be greater than current time.',
        });
      }, 100);
      setFormSubmitted(false);
      return;
    }

    let validationError = '';

    if (fileData.selectedFile) {
      validationError = validateSelectedImageFile(fileData.selectedFile);

      if (validationError) {
        showToastMsg('error', validationError);
        setFormSubmitted(false);
        setLoading(false);
      } else {
        updateData(data);
      }
    } else {
      updateData(data);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewCommunityGatheringById(dataId)
      .then((res) => {
        gatheringStartTimeRef.current = res.data?.gatheringStartDateTime;
        // Fill form values
        setValue('title', res.data?.title || '');
        setValue('description', res.data?.description || '');
        setValue('thumbnail', res.data?.bannerImageUrl || '');
        setValue('zoomLink', res.data?.zoomLink || '');
        setValue('password', res.data?.zoomPassword || '');
        setValue('status', res.data?.status || 0);
        setValue(
          'gatheringStartDateTime',
          dayjs.unix(res.data?.gatheringStartDateTime) || null
        );
        setValue('gatheringDuration', res.data?.gatheringDuration || 0);
        setValue('recurringType', res.data?.isRecurring || 0);
        setValue('frequencyType', res.data?.frequencyType || null);
        if (res.data?.frequencyType === FREQUENCY_TYPE.WEEKLY.value) {
          setSelectedDays(res.data?.recurringDays || null);
          setSelectedWeeks(res.data?.weekNumbers || null);
        } else {
          setValue('monthDays', res.data?.recurringDays || null);
        }
        if (res.data?.recurringEndDateTime) {
          setValue(
            'recurringEndDateTime',
            dayjs.unix(res.data?.recurringEndDateTime)
          );
        }

        setVideoData({
          ...videoData,
          thumbnail: res.data?.bannerImageUrl || '',
        });

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(80) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Community Gathering Title"
                      type="text"
                      error={!!errors.title}
                      helperText={errors?.title?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 80 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      type="text"
                      error={!!errors.description}
                      helperText={errors?.description?.message || null}
                      fullWidth
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="zoomLink"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Zoom Link Url"
                      type="text"
                      error={!!errors.zoomLink}
                      helperText={errors?.zoomLink?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Zoom Password"
                      type={showPassword ? 'text' : 'password'}
                      error={!!errors.password}
                      helperText={errors?.password?.message || null}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              <Iconify
                                icon={
                                  showPassword
                                    ? 'eva:eye-fill'
                                    : 'eva:eye-off-fill'
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="status"
                      name="status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="gatheringStartDateTime"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Datetimepicker
                      id="gatheringStartDateTime"
                      name="gatheringStartDateTime"
                      label="Community Gathering Start Date"
                      value={value}
                      defaultValue={!dataId ? null : value}
                      onChange={onChange}
                      error={errors?.gatheringStartDateTime?.message || ''}
                      disablePast
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="gatheringDuration"
                  control={control}
                  rules={
                    (validationRules.REQUIRED,
                    validationRules.REQUIRED_NUMBER,
                    maxLengthValidation(3))
                  }
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <TextField
                        {...field}
                        label="Gathering Duration (in minutes)"
                        type="text"
                        error={!!errors.gatheringDuration}
                        helperText={errors?.gatheringDuration?.message || null}
                        sx={{ width: '100%' }}
                        fullWidth
                      />
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="recurringType"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <RecurringType
                      id="recurringType"
                      name="recurringType"
                      label="Recurring?"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.recurringType?.message || ''}
                    />
                  )}
                />
              </Grid>

              {watchRecurring === 1 && (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="frequencyType"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field: { onChange, value } }) => (
                        <FrequencyTypes
                          id="frequencyType"
                          name="frequencyType"
                          label="Frequency Type"
                          defaultValue={value}
                          onChange={onChange}
                          sx={{ width: '100%' }}
                          error={errors?.frequencyType?.message || ''}
                          // disabled={!!dataId}
                        />
                      )}
                    />
                  </Grid>
                  {watchFrequency === FREQUENCY_TYPE.WEEKLY.value && (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <Controller
                          name="weekNumbers"
                          control={control}
                          rules={{
                            validate: {
                              required: () =>
                                (selectedWeeks && selectedWeeks.length > 0) ||
                                validationRules.REQUIRED.required.message,
                            },
                          }}
                          // eslint-disable-next-line no-unused-vars
                          render={({ field: { onChange, value } }) => (
                            <WeekNumber
                              id="weekNumbers"
                              name="weekNumbers"
                              label="Select Occurrence"
                              value={value}
                              defaultValue={value}
                              onChange={handleWeekChange}
                              validOptions={[0, 1, 2, 3, 4]}
                              selectedWeeks={selectedWeeks}
                              handleWeekChange={handleWeekChange}
                              sx={{ width: '100%' }}
                              error={errors?.weekNumbers?.message || ''}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Controller
                          name="day"
                          control={control}
                          rules={{
                            validate: {
                              required: () =>
                                (selectedDays && selectedDays.length > 0) ||
                                validationRules.REQUIRED.required.message,
                            },
                          }}
                          // eslint-disable-next-line no-unused-vars
                          render={({ field: { onChange, value } }) => (
                            <DayNames
                              id="day"
                              name="day"
                              label="Select Days"
                              value={value}
                              defaultValue={value}
                              onChange={handleDayChange}
                              validOptions={[0, 1, 2, 3, 4, 5, 6]}
                              selectedDays={selectedDays}
                              handleDayChange={handleDayChange}
                              sx={{ width: '100%' }}
                              error={errors?.day?.message || ''}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  {watchFrequency === FREQUENCY_TYPE.MONTHLY.value && (
                    <Grid item xs={12} sm={6} md={6}>
                      <Controller
                        name="monthDays"
                        control={control}
                        rules={validationRules.REQUIRED}
                        render={({ field: { onChange, value } }) => (
                          <DateList
                            id="monthDays"
                            name="monthDays"
                            label="Select Month Days"
                            multiple
                            defaultValue={value}
                            onChange={onChange}
                            sx={{ width: '100%', my: 1 }}
                            error={errors?.monthDays?.message || ''}
                          />
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="recurringEndDateTime"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field: { onChange, value } }) => (
                        <Datepicker
                          id="recurringEndDateTime"
                          name="recurringEndDateTime"
                          label="Recurring Ending On"
                          value={value}
                          defaultValue={value}
                          onChange={onChange}
                          sx={{ width: 600 }}
                          error={errors?.recurringEndDateTime?.message || ''}
                          disablePast
                          maxDate={maxDate}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={12} md={12}>
                <Box
                  component="section"
                  sx={{
                    p: 3,
                    border: '1px solid lightgrey',
                    borderRadius: '5px',
                    width: '100%',
                    marginTop: 3,
                  }}
                >
                  <ImageUpload
                    title="Thumbnail"
                    dataId={dataId}
                    showToastMsg={showToastMsg}
                    setSnackbarInfo={setSnackbarInfo}
                    videoData={videoData}
                    loading={loading}
                    showUpdatedImage={showUpdatedImage}
                    uploadFrom="form"
                    setFileData={setFileData}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditCommunityGathering.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditCommunityGathering;
