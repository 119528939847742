import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ImageUpload from './image-upload.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const UploadPosters = ({
  title,
  dataId,
  onCancel,
  squareImageUrl,
  horizontalImageUrl,
  isArticleContent,
  popularContent,
  isCalendarEventPoster,
}) => {
  const [loading, setLoading] = useState(true);
  const [squareImageData, setSquareImageData] = useState({
    posterURL: '',
  });
  const [horizontalImageData, setHorizontalImageData] = useState({
    posterURL: '',
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const showUpdatedImage = () => {};

  const setSquareImageUrlCallBack = (v) => {
    setSquareImageData({
      posterURL: v.imageUrl,
    });
  };

  const setHorizontalImageUrlCallBack = (v) => {
    setHorizontalImageData({
      posterURL: v.imageUrl,
    });
  };

  const showToastMsg = (msgType, message) => {
    if (msgType === 'error') {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }

    if (msgType === 'success') {
      setSnackbarInfo({
        show: true,
        type: 'success',
        message,
      });
    }
  };

  useEffect(() => {
    setSquareImageData({
      ...squareImageData,
      posterURL: squareImageUrl || '',
    });
    setHorizontalImageData({
      ...horizontalImageData,
      posterURL: horizontalImageUrl || '',
    });
    setLoading(false);
  }, []);
  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{`${title}`}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        <form id="upload-assets-form">
          <Box
            component="section"
            sx={{
              p: 3,
              border: '1px solid lightgrey',
              borderRadius: '5px',
              width: '100%',
              marginTop: 3,
            }}
          >
            <ImageUpload
              title={popularContent ? 'Square Poster' : 'Poster'}
              dataId={dataId}
              showToastMsg={showToastMsg}
              setSnackbarInfo={setSnackbarInfo}
              videoData={squareImageData}
              loading={loading}
              showUpdatedImage={showUpdatedImage}
              setImageUrlCallBack={setSquareImageUrlCallBack}
              isArticleContent={isArticleContent}
              popularContent={popularContent}
              bannerType="square"
              isCalendarEventPoster={isCalendarEventPoster}
            />
          </Box>

          <Box
            component="section"
            sx={{
              p: 3,
              border: '1px solid lightgrey',
              borderRadius: '5px',
              width: '100%',
              marginTop: 3,
            }}
          >
            <ImageUpload
              title="Horizontal Poster"
              dataId={dataId}
              showToastMsg={showToastMsg}
              setSnackbarInfo={setSnackbarInfo}
              videoData={horizontalImageData}
              loading={loading}
              showUpdatedImage={showUpdatedImage}
              setImageUrlCallBack={setHorizontalImageUrlCallBack}
              isArticleContent={isArticleContent}
              popularContent={popularContent}
              bannerType="horizontal"
              isCalendarEventPoster={isCalendarEventPoster}
            />
          </Box>
        </form>
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

UploadPosters.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  squareImageUrl: PropTypes.string,
  horizontalImageUrl: PropTypes.string,
  isArticleContent: PropTypes.bool,
  popularContent: PropTypes.bool,
  isCalendarEventPoster: PropTypes.bool,
};

UploadPosters.defaultProps = {
  isArticleContent: false,
  popularContent: false,
  squareImageUrl: '',
  horizontalImageUrl: '',
  isCalendarEventPoster: false,
};

export default UploadPosters;
