import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import {
  getSearchableListContent,
  getSubscribedContentList,
} from '../../services/content.service';
import { formatTimestamp } from '../../utils/datetime.util';

const Content = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  sx = {},
  error = '',
  type = '',
  isPaid = null,
  subscriberId = null,
  subscriberEmail = '',
}) => {
  const [contentData, setContentData] = useState([]);

  useEffect(() => {
    if (subscriberId && subscriberEmail) {
      getSubscribedContentList(subscriberEmail)
        .then((res) => {
          setContentData(res.data);
        })
        .catch(() => {
          // nothing
        });
    } else {
      getSearchableListContent(type, isPaid)
        .then((res) => {
          setContentData(res.data);
        })
        .catch(() => {
          // nothing
        });
    }
  }, [subscriberId, subscriberEmail]);

  return (
    <FormControl fullWidth error={error !== ''}>
      <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={id}
        name={name}
        value={defaultValue}
        label={label}
        onChange={onChange}
        sx={sx}
        defaultValue={defaultValue}
      >
        {contentData.length > 0 &&
          contentData.map((k) => (
            <MenuItem key={`${id}-v-${k.id}`} value={`${k.id}`}>
              {`${
                subscriberId && subscriberEmail
                  ? `${k.contentType} - ${
                      k.contentTitle ? k.contentTitle : ''
                    } (${k.type}) - (${formatTimestamp(k.endDate)})`
                  : k.name
              }`}
            </MenuItem>
          ))}
      </Select>
      {error !== '' && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

Content.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape().isRequired,
  error: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isPaid: PropTypes.number.isRequired,
  subscriberId: PropTypes.number.isRequired,
  subscriberEmail: PropTypes.string.isRequired,
};

export default Content;
