import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, CONTENT_ALIAS } from '../const.config';

export const LISTING_POPULAR_CONTENT_COLUMNS = [
  {
    id: 'thumbnail',
    label: 'Image Preview',
    width: '5%',
    align: 'center',
    dataKey: 'squareBanner',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
  },
  {
    id: 'title',
    label: 'Title',
    width: '15%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'contentType',
    label: 'Content Type',
    width: '20%',
    align: 'left',
    dataKey: 'contentType',
    sortable: false,
    type: CELL_DATA_TYPES.UCWORDS,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const VIEW_POPULAR_CONTENT_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'contentType',
    label: `${CONTENT_ALIAS.CONTENT_TYPE}`,
    dataKey: 'contentType',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'square_banner',
    label: `${CONTENT_ALIAS.SQUARE_BANNER} URL`,
    dataKey: 'squareBanner',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'horiz_banner',
    label: `${CONTENT_ALIAS.HORIZONTAL_BANNER} URL`,
    dataKey: 'horizBanner',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_POPULAR_CONTENT_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.TRANSLATE.value,
    label: ACTIONS.TRANSLATE.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.UPLOAD_POSTER.value,
    label: ACTIONS.UPLOAD_POSTER.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];
