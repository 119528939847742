import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DialogActions, Button, Grid, Box, Card } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import ListData from '../table-elements/list-data.component';
import ConfirmPopup from '../common/confirm-popup.component';
import { validateSelectedSrtFile } from '../../utils/file-validations.util';
import {
  deleteSrtToBunnyCDN,
  uploadSrtToBunnyCDN,
  viewCaptionsById,
} from '../../services/content.service';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/srt.config';
import LanguageTypes from '../form-elements/lang-types.component';
import { ACTIONS } from '../../config/const.config';

const SrtUpload = ({ dataId, showToastMsg, setSnackbarInfo }) => {
  const {
    formState: { errors },
  } = useForm({
    defaultValues: {
      videoUrl: '',
    },
  });
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 5,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFilesObj, setSelectedFilesObj] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);
  const [selectedLang, setSelectedLang] = useState('');
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSelectFiles = async (files) => {
    setSelectedFilesObj(files);
    setSnackbarInfo({
      show: false,
    });
    await sleep(100);

    const validationError = validateSelectedSrtFile(files);
    if (validationError) {
      showToastMsg('error', validationError);
    }

    // Show file name below button
    for (let i = 0; i < files.length; i += 1) {
      setSelectedFileName(files[i].name);
    }
  };

  const handleUploadFiles = async (videoId, fileName) => {
    setDisableBtn(true);
    setSnackbarInfo({
      show: false,
    });
    await sleep(100);
    if (selectedLang === '') {
      showToastMsg('error', 'Please select language');
      setDisableBtn(false);
    } else {
      const validationError = validateSelectedSrtFile(selectedFilesObj);
      if (validationError) {
        showToastMsg('error', validationError);
        setDisableBtn(false);
      } else {
        const payload = {
          videoId,
          file: selectedFilesObj[0],
          fileName,
          type: 'srt',
          languageCode: selectedLang.split('-')[1],
          languageName: selectedLang.split('-')[0],
        };
        try {
          const cdnResponse = await uploadSrtToBunnyCDN(payload);
          if (cdnResponse.data?.success) {
            setSelectedFilesObj(null);
            setSelectedFileName('');
            showToastMsg('success', `File Uploaded Successfully!`);
            setSelectedLang('');
            setDisableBtn(false);
            setOptions({
              ...options,
              loading: false,
              totalRows: cdnResponse?.data?.data.length || 0,
              rows: cdnResponse?.data?.data || [],
              error: false,
            });
          } else {
            showToastMsg('error', 'Something went wrong! Please try again.');
            setDisableBtn(false);
          }
        } catch (err) {
          showToastMsg(
            'error',
            err?.response?.data?.message ||
              'Something went wrong! Please try again.'
          );
          setDisableBtn(false);
        }
      }
    }
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };

  const handleDeleteFiles = async () => {
    setSnackbarInfo({
      show: false,
    });
    const payload = {
      videoId: dataId,
      languageCode: doAction.data.code,
    };

    try {
      const cdnResponse = await deleteSrtToBunnyCDN(payload);

      if (cdnResponse.data?.success) {
        setSelectedFilesObj(null);
        setSelectedFileName('');
        showToastMsg('success', `File Deleted Successfully!`);
        setOptions({
          ...options,
          loading: false,
          totalRows: cdnResponse?.data?.data.length || 0,
          rows: cdnResponse?.data?.data || [],
          error: false,
        });
        handleActionCancel();
      } else {
        handleActionCancel();
        showToastMsg('error', 'Something went wrong! Please try again.');
      }
    } catch {
      showToastMsg('error', 'Something went wrong! Please try again.');
    }
  };

  const handleChange = (e) => {
    setSelectedLang(e.target.value);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const LinearProgressWithLabel = (props) => {
    const { value } = props;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  };

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

  useEffect(() => {
    viewCaptionsById(dataId)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch((err) => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });

        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          show: true,
          type: 'error',
          message,
        });
      });
  }, [dataId]);

  return (
    <Grid container spacing={2}>
      <Grid item sx={{ fontWeight: 'bold' }} xs={12} sm={12} md={12}>
        Captions
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Card>
          <Grid item sx={{ marginTop: 2 }}>
            List of caption files:
          </Grid>
          <ListData
            columns={columns}
            rows={options.rows}
            page={options.page}
            rowsPerPage={options.totalRows}
            totalRows={options.totalRows}
            loading={options.loading}
            actions={actions}
            error={options.error}
            sortBy={options.sortBy}
            sortOrder={options.sortOrder}
            onPageChange={() => {}}
            onRowsPerPageChange={() => {}}
            onSortChange={() => {}}
            onAction={handleActionSelect}
          />
        </Card>
        {doAction.action === ACTIONS.DELETE.value && (
          <ConfirmPopup
            title="Delete SRT File for respective language"
            message="Do you want to delete SRT file? You can not undo this action!"
            onCancel={handleActionCancel}
            onSuccess={handleDeleteFiles}
          />
        )}
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <Grid item xs={12} sm={12} md={12}>
          <form id="add-edit-form">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <LanguageTypes
                  id="languageType"
                  name="languageType"
                  label="Select Language"
                  defaultValue={selectedLang}
                  onChange={(e) => handleChange(e)}
                  sx={{ width: '100%' }}
                  error={errors?.videoType?.message || ''}
                  disabled={false}
                />
              </Grid>
            </Grid>
          </form>
          <Grid
            item
            sx={{
              display: 'flex',
              paddingTop: 2,
            }}
          >
            <Box sx={{ fontWeight: 'bold' }}>Note:&nbsp;</Box>
            Select language before selecting caption file.
          </Grid>

          <Dropzone
            onDrop={(acceptedFiles) => handleSelectFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />

                  <Box
                    component="section"
                    sx={{
                      p: 3,
                      border: '1px dashed grey',
                      borderRadius: '20px',
                      width: '100%',
                      marginTop: 3,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        Add SRT file
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          Drag and drop SRT files here, or click to browse
                        </Grid>

                        <DialogActions sx={{ justifyContent: 'center' }}>
                          <Button
                            component="label"
                            variant="contained"
                            disabled={disableBtn}
                            sx={{
                              backgroundColor: '#808080',
                            }}
                          >
                            Select SRT File
                          </Button>
                        </DialogActions>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {`${selectedFileName}`}
                    </Grid>
                  </Box>
                </div>
              </section>
            )}
          </Dropzone>
        </Grid>

        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            color="primary"
            variant="contained"
            disabled={disableBtn}
            onClick={() => handleUploadFiles(dataId, selectedFileName)}
          >
            Upload SRT File
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

SrtUpload.propTypes = {
  dataId: PropTypes.number.isRequired,
  showToastMsg: PropTypes.func.isRequired,
  setSnackbarInfo: PropTypes.object.isRequired,
};

export default SrtUpload;
