import React from 'react';
import PropTypes from 'prop-types';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListVideoSequence from '../../components/content/list-video-sequence.component';
import { VIDEO_TYPES } from '../../config/const.config';

const VideoSequenceView = ({ type }) => {
  let pageTitle;

  if (type === VIDEO_TYPES.TAO_SONG.value) {
    pageTitle = PAGE_TITLE_LIST.CONTENT_SEQUENCE_TAOSONG;
  } else if (type === VIDEO_TYPES.BLESSINGS.value) {
    pageTitle = PAGE_TITLE_LIST.CONTENT_SEQUENCE_BLESSING;
  } else if (type === VIDEO_TYPES.MEDITATION.value) {
    pageTitle = PAGE_TITLE_LIST.CONTENT_SEQUENCE_MEDITATION;
  }

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListVideoSequence type={type} />
    </AppLayout>
  );
};

VideoSequenceView.propTypes = {
  type: PropTypes.string.isRequired,
};

export default VideoSequenceView;
