import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListTestimonialCategories from '../../components/content/list-testimonial-categories.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentCategoriesView = () => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_TESTIMONIALS_CATEGORIES}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListTestimonialCategories />
    </AppLayout>
  );
};

ContentCategoriesView.propTypes = {};

export default ContentCategoriesView;
