import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';
import { SUBSCRIBER_ROLE } from '../roles.config';

// Manage members module configurations
export const LISTING_COLUMNS = [
  {
    id: 'memberName',
    label: 'Member Name',
    width: '20%',
    align: 'left',
    dataKey: 'memberName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ed',
    label: 'Membership End Date',
    width: '30%',
    align: 'center',
    dataKey: 'ed',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
    conditional: (val) => val.role === SUBSCRIBER_ROLE.NORMAL_USER.value,
  },
];

export const VIEW_GROUPS_COLUMNS = [
  {
    id: 'memberName',
    label: 'Member Name',
    dataKey: 'memberName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ed',
    label: 'Membership End Date',
    dataKey: 'ed',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
