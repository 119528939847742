import React, { useEffect, useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Grid,
  TextField,
  Box,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import validationRules from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewGroupMember,
  inviteNewGroupMember,
  updateGroupMemberById,
  viewGroupMemberById,
} from '../../services/content.service';
import Datepicker from '../form-elements/datepicker.component';
import { convertDateStringToTimestamp } from '../../utils/datetime.util';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditMember = ({
  title,
  dataId,
  conversationId,
  onCancel,
  onSuccess,
  snackbarMessageCallback,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      member: '',
      membershipEndDate: null,
    },
  });
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [memberEmails, setMemberEmails] = useState({
    newAdded: [],
    notRegisterd: [],
    invalid: [],
  });
  const [inviting, setInviting] = useState(false);
  const sentInvitationRef = useRef(false);

  const handleInvite = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    setInviting(true);

    const accessEndTime = getValues('membershipEndDate');

    inviteNewGroupMember(conversationId, {
      inviteMembers: memberEmails.notRegisterd,
      accessEndTime: convertDateStringToTimestamp(accessEndTime),
    })
      .then((res) => {
        if (res.data?.success) {
          sentInvitationRef.current = true;
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'Invitation sent successfully.',
          });
        }
        setInviting(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setInviting(false);
      });
  };

  const saveNewData = (payload) => {
    createNewGroupMember(payload)
      .then((res) => {
        setMemberEmails({
          newAdded: res.data?.newAddedEmails,
          notRegisterd: res.data?.notRegisterdEmails,
          invalid: res.data?.invalidEmails,
        });
        onSuccess('', false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateGroupMemberById(conversationId, dataId, payload)
      .then(() => {
        onSuccess('Member details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = async (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const membershipEndDateGMT =
      Date.parse(data.membershipEndDate?.$d.toISOString()) / 1000;

    const members = data.member
      .trim()
      .split('\n')
      .map((email) => email.trim());

    const payload = {
      conversationId,
      members,
      membershipEndDate: membershipEndDateGMT,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewGroupMemberById(conversationId, dataId)
      .then((res) => {
        // Fill form values
        setValue('member', res?.data?.email || '');
        setValue('membershipEndDate', dayjs.unix(res?.data?.ed) || null);

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth={
        memberEmails.newAdded?.length > 0 ||
        memberEmails.notRegisterd?.length > 0 ||
        memberEmails.invalid?.length > 0
          ? 'lg'
          : 'md'
      }
    >
      <DialogTitle id="view-dialog-title">
        {title}
        {(memberEmails.newAdded?.length > 0 ||
          memberEmails.notRegisterd?.length > 0 ||
          memberEmails.invalid?.length > 0) &&
          ' - Summary'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
        disabled={inviting}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              {!memberEmails.newAdded?.length &&
                !memberEmails.notRegisterd?.length &&
                !memberEmails.invalid?.length && (
                  <>
                    <Grid item xs={12} sm={6} md={6}>
                      <Controller
                        name="member"
                        control={control}
                        rules={validationRules.REQUIRED}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="Members (Email)"
                            type="text"
                            multiline
                            minRows={!dataId && 5}
                            maxRows={15}
                            onChange={onChange}
                            value={value}
                            error={!!errors.member}
                            helperText={errors?.member?.message || null}
                            disabled={dataId}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-8px' }}>
                      <Controller
                        name="membershipEndDate"
                        control={control}
                        rules={validationRules.REQUIRED}
                        render={({ field: { onChange, value } }) => (
                          <Datepicker
                            id="membershipEndDate"
                            name="membershipEndDate"
                            label="Membership End Date"
                            value={value}
                            defaultValue={!dataId ? null : value}
                            onChange={onChange}
                            error={errors?.membershipEndDate?.message || ''}
                            disablePast
                            sx={{ paddingTop: 0, width: '100%' }}
                          />
                        )}
                      />
                    </Grid>
                    {!dataId && (
                      <Grid
                        item
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          paddingTop: 2,
                        }}
                      >
                        <Box sx={{ fontWeight: 'bold' }}>Note:&nbsp;</Box>
                        You can add multiple email at a time. But enter one
                        email per line.
                      </Grid>
                    )}
                  </>
                )}

              {(memberEmails.newAdded?.length > 0 ||
                memberEmails.notRegisterd?.length > 0 ||
                memberEmails.invalid?.length > 0) && (
                <>
                  <Grid item xs={12} sm={4} md={4}>
                    <Box marginY={1}>
                      Already added/Existing members:{' '}
                      {memberEmails.newAdded?.length || 0}
                      <CopyToClipboard text={memberEmails.newAdded?.join('\n')}>
                        <IconButton
                          aria-label="close"
                          onClick={() =>
                            snackbarMessageCallback(
                              'success',
                              'Text copied to clipboard.'
                            )
                          }
                          disabled={!memberEmails.newAdded?.length}
                          size="small"
                          sx={{ color: (theme) => theme.palette.grey[500] }}
                        >
                          <Iconify icon="solar:copy-bold" />
                        </IconButton>
                      </CopyToClipboard>
                    </Box>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        label="Already added/Existing members"
                        type="text"
                        multiline
                        minRows={5}
                        maxRows={25}
                        value={memberEmails.newAdded?.join('\n')}
                        disabled
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <Box
                      marginY={1}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box>
                        Emails not registered:{' '}
                        {memberEmails.notRegisterd?.length || 0}
                        <CopyToClipboard
                          text={memberEmails.notRegisterd?.join('\n')}
                        >
                          <IconButton
                            aria-label="close"
                            onClick={() =>
                              snackbarMessageCallback(
                                'success',
                                'Text copied to clipboard.'
                              )
                            }
                            disabled={!memberEmails.notRegisterd?.length}
                            size="small"
                            sx={{ color: (theme) => theme.palette.grey[500] }}
                          >
                            <Iconify icon="solar:copy-bold" />
                          </IconButton>
                        </CopyToClipboard>
                      </Box>

                      <Button
                        type="button"
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => handleInvite()}
                        disabled={
                          inviting ||
                          sentInvitationRef.current ||
                          !memberEmails.notRegisterd?.length
                        }
                      >
                        {inviting && (
                          <CircularProgress
                            disableShrink
                            size="18px"
                            sx={{ marginRight: 1, color: '#919eabcc' }}
                          />
                        )}
                        {sentInvitationRef.current && 'Invited'}
                        {!sentInvitationRef.current &&
                          (inviting ? 'Inviting...' : 'Invite')}
                      </Button>
                    </Box>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        label="Emails not registered"
                        type="text"
                        multiline
                        minRows={5}
                        maxRows={25}
                        value={memberEmails.notRegisterd?.join('\n')}
                        disabled
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <Box marginY={1}>
                      Invalid Emails: {memberEmails.invalid?.length || 0}
                      <CopyToClipboard text={memberEmails.invalid?.join('\n')}>
                        <IconButton
                          aria-label="close"
                          onClick={() =>
                            snackbarMessageCallback(
                              'success',
                              'Text copied to clipboard.'
                            )
                          }
                          disabled={!memberEmails.invalid?.length}
                          size="small"
                          sx={{ color: (theme) => theme.palette.grey[500] }}
                        >
                          <Iconify icon="solar:copy-bold" />
                        </IconButton>
                      </CopyToClipboard>
                    </Box>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        label="Invalid Emails"
                        type="text"
                        multiline
                        minRows={5}
                        maxRows={25}
                        value={memberEmails.invalid?.join('\n')}
                        disabled
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        )}
      </DialogContent>

      {!memberEmails.newAdded?.length &&
        !memberEmails.notRegisterd?.length &&
        !memberEmails.invalid?.length && (
          <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
            <Button
              type="submit"
              form="add-edit-form"
              color="primary"
              variant="contained"
              disabled={loading || formSubmitted}
            >
              Save
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={onCancel}
              disabled={formSubmitted}
            >
              Cancel
            </Button>
          </DialogActions>
        )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditMember.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  conversationId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  snackbarMessageCallback: PropTypes.func.isRequired,
};

export default AddEditMember;
