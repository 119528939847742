/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { WEEK } from '../../config/const.config';

const WeekNumber = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  validOptions,
  selectedWeeks,
  handleWeekChange,
  sx,
  error,
  disabled,
}) => (
  <FormControl fullWidth error={error !== ''}>
    <Typography>{label}:</Typography>
    <div style={{ display: 'flex' }}>
      {validOptions.map((k) => (
        <FormControlLabel
          key={k}
          control={
            <Checkbox
              checked={selectedWeeks.includes(k)}
              onChange={() => handleWeekChange(k)}
            />
          }
          label={WEEK[k]}
        />
      ))}
    </div>

    {error !== '' && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

WeekNumber.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  validOptions: PropTypes.array.isRequired,
  selectedWeeks: PropTypes.array,
  handleWeekChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  disabled: PropTypes.number,
};
WeekNumber.defaultProps = {
  selectedWeeks: [],

  sx: {},
  error: '',
  disabled: null,
};

export default WeekNumber;
