import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { VIDEO_TYPES } from '../../config/const.config';

const VideoTypes = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  sx,
  invalidOptions = [],
  error,
  disabled,
  searchList = false,
}) => {
  const [focused, setFocused] = useState(false);

  let renderOptions = Object.entries(VIDEO_TYPES);

  if (searchList) {
    renderOptions = [
      ['ALL_VIDEOS', { label: 'All Videos', value: 0 }],
      ...renderOptions,
    ];
  }

  return (
    <FormControl fullWidth error={error !== ''}>
      <InputLabel
        id={`field-${id}-select-label`}
        sx={
          searchList
            ? {
                marginLeft: '10px',
                marginTop: focused || defaultValue ? 0 : '-8px',
              }
            : {}
        }
      >
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={id}
        name={name}
        value={defaultValue}
        label={label}
        onChange={onChange}
        sx={sx}
        disabled={disabled}
        onOpen={() => setFocused(true)}
        onClose={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        {renderOptions
          .filter((vt) => !invalidOptions.includes(vt[1].value))
          .map((k) => (
            <MenuItem key={`${id}-v-${k[1].value}`} value={k[1].value}>
              {k[1].label}
            </MenuItem>
          ))}
      </Select>
      {error !== '' && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

VideoTypes.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  invalidOptions: PropTypes.array.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.number,
  searchList: PropTypes.bool.isRequired,
};
VideoTypes.defaultProps = {
  sx: {},
  error: '',
  disabled: null,
};

export default VideoTypes;
