import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const adminUserLogin = async (payload) => {
  const options = prepareRequestOptions('/admin/auth/login', 'POST', payload);

  const response = await axios(options);
  return response.data;
};

export const adminUserLogout = async () => {
  const options = prepareRequestOptions(
    '/admin/auth/logout',
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const adminUserValidateSession = async () => {
  const options = prepareRequestOptions(
    '/admin/auth/validate',
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
