import CELL_DATA_TYPES from '../cell-types.config';

export const LISTING_COLUMNS = [
  {
    id: 'Sr No',
    label: 'Sr. No',
    width: '5%',
    align: 'right',
    dataKey: 'Sr No',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'name',
    label: 'Subscriber Name',
    width: '20%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Subscriber Email',
    width: '30%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'registrationDate',
    label: 'Registration Date',
    width: '15%',
    align: 'left',
    dataKey: 'registrationDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'joinDate',
    label: 'Join Date',
    width: '15%',
    align: 'left',
    dataKey: 'joinDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'title',
    label: 'Event',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'isPaid',
    label: 'Is Paid',
    dataKey: 'isPaid',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'isRecurring',
    label: 'Is Recurring',
    dataKey: 'isRecurring',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'subscriptionsCount',
    label: 'Current Active Paid Subscriptions',
    dataKey: 'subscriptionsCount',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'totalRegistrations',
    label: 'Total Subscribers Registered',
    dataKey: 'totalRegistrations',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'totalJoined',
    label: 'Total Subscribers Joined',
    dataKey: 'totalJoined',
    type: CELL_DATA_TYPES.NUMBER,
  },
];
