import React, { useState, useEffect, useRef } from 'react';
import { Card, Stack, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/groups.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import {
  deleteGroupById,
  getDataList,
  toggleStatus,
} from '../../services/content.service';
import { ACTIONS } from '../../config/const.config';
import ConfirmPopup from '../common/confirm-popup.component';
import AddEditGroup from './add-edit-group.component';
import ViewGroup from './view-group.component';
import SearchList from '../table-elements/search-list.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ROUTE_PATH from '../../config/routes.config';
import editUrl from '../../utils/url-edit.util';
import PosterUpload from '../content/poster-upload.component';
import ImagePreview from '../content/image-preview.component';
import TranslateGroups from './translate-group.component';

const ListGroups = () => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const searchGroupRef = useRef(null);
  const navigate = useNavigate();
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [imagePreview, setImagePreview] = useState({
    show: false,
    imageUrl: '',
    title: '',
  });
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      searchValue: e.target.value || '',
    });
  };

  const handleSearchGroups = () => {
    if (options.loading) return;
    setOptions({
      ...options,
      q: searchGroupRef.current.value,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
      page: 0,
    });
  };

  const handleClearSearch = () => {
    if (options.loading) return;
    setOptions({
      ...options,
      searchValue: '',
      q: null,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
    searchGroupRef.current.value = '';
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
    setImagePreview({
      ...imagePreview,
      show: false,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleImagePreview = (rowData) => {
    if (rowData.thumbnail) {
      setImagePreview({
        ...imagePreview,
        show: true,
        imageUrl: rowData.thumbnail,
        title: rowData.name,
      });
    }
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteGroupById(doAction.data.id)
      .then(() => {
        handleActionSuccess('Group deleted successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleToggleStatus = (r, value) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      id: r.id,
      status: value ? 0 : 1,
      type: 'group',
    };

    toggleStatus(payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Status updated.',
        });
      })
      .catch((e) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: e.response?.data?.message,
        });
      });
  };

  useEffect(() => {
    let uri = 'groups';
    uri += `?page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    }

    getDataList(uri)
      .then((res) => {
        let serialRow;
        if (res?.data?.rows) {
          serialRow = res.data.rows;
          serialRow = serialRow.map((tr, count) => ({
            ...tr,
            serial: options.page * options.rowsPerPage + count + 1,
          }));
        }

        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: serialRow || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add" />}
            onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
          >
            Create New
          </Button>
          <SearchList
            label="Groups"
            handleSearch={handleSearchGroups}
            inputRef={searchGroupRef}
            onChange={handleSearchChange}
            options={options}
            clearSearch={handleClearSearch}
            loading={options.loading}
          />
        </div>
        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onAction={handleActionSelect}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          handleToggleStatus={handleToggleStatus}
          handleImagePreview={handleImagePreview}
        />
      </Card>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditGroup
          title="Add New Group"
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditGroup
          title={`Edit Group Details - ${doAction.data.name}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewGroup
          title={`Group Details - ${doAction.data.name}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.UPLOAD_POSTER.value && (
        <PosterUpload
          title={`Group - Upload Poster - ${doAction.data.name}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          imageUrl={doAction.data.thumbnail}
          communityGroup
        />
      )}

      {doAction.action === ACTIONS.TRANSLATE.value && (
        <TranslateGroups
          title={`Translate Group Details - ${doAction.data.name}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.MANAGE_MEMBERS.value &&
        navigate(editUrl(ROUTE_PATH.GROUPS_MANAGE_MEMBERS, doAction.data.id), {
          state: { name: doAction.data?.name || '' },
        })}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title="Delete Group"
          message={`Do you want to delete ${doAction.data.name} group? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {imagePreview.show && (
        <ImagePreview
          title={`Image - ${imagePreview.title}`}
          onCancel={handleActionCancel}
          imageUrl={imagePreview.imageUrl}
        />
      )}
      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListGroups;
