import React, { useEffect, useState } from 'react';
import {
  Stack,
  Grid,
  Card,
  CardHeader,
  Box,
  Button,
  IconButton,
} from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import JsFileDownloader from 'js-file-download';
import useChart from '../../hooks/charts.hook';
import { getDate, formatDate } from '../../utils/datetime.util';
import { getVideoWatchSessionsReport } from '../../services/reports.service';
import Datepicker from '../form-elements/datepicker.component';
import Iconify from '../common/iconify.component';
import { CONTENT_ALIAS } from '../../config/const.config';

const WatchedSecondsChart = () => {
  const [filters, setFilters] = useState({
    startDate: getDate(-7),
    endDate: getDate(0),
    reloadCounter: 0,
  });
  const [seriesData, setSeriesData] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const [chartStatus, setChartStatus] = useState('');

  const chartOptions = useChart({
    chart: {
      toolbar: {
        show: false,
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
        },
      },
    },
    title: {
      text: 'Video Watch Analytics',
    },
    stroke: {
      width: [0, 4],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    labels: xLabels,
    xaxis: {
      type: 'date',
    },
    yaxis: [
      {
        title: {
          text: 'Total Minutes Watched',
        },
      },
      {
        opposite: true,
        title: {
          text: 'Total Sessions',
        },
      },
    ],
  });

  const handleStartDateChange = (v) => {
    setFilters({
      ...filters,
      startDate: v,
      reloadCounter: filters.reloadCounter + 1,
    });
  };
  const handleEndDateChange = (v) => {
    setFilters({
      ...filters,
      endDate: v,
      reloadCounter: filters.reloadCounter + 1,
    });
  };
  const handleRefreshData = () => {
    setFilters({
      ...filters,
      reloadCounter: filters.reloadCounter + 1,
    });
  };

  useEffect(() => {
    setChartStatus('loading');
    const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');

    const payload = {
      startDate,
      endDate,
      reportType: 'session_chart',
      output: 'json',
    };

    getVideoWatchSessionsReport(payload).then((res) => {
      const updatedSeriesData = [
        {
          name: 'Total Minutes Watched',
          type: 'column',
          data: [],
        },
        {
          name: 'Total Watch Sessions',
          type: 'line',
          data: [],
        },
      ];
      const labels = [];

      res.data.rows.forEach((v) => {
        updatedSeriesData[0].data.push(v.totalWatched);
        updatedSeriesData[1].data.push(v.totalSessions);
        labels.push(v.watchDate);
      });
      if (updatedSeriesData[0].data.length === 0) {
        setChartStatus('nodata');
      } else {
        setChartStatus('show');
      }
      setSeriesData(updatedSeriesData);
      setXLabels(labels);
    });
  }, [filters.reloadCounter]);

  const downloadReport = () => {
    const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');

    const payload = {
      startDate,
      endDate,
      reportType: 'session_export',
      output: 'csv',
    };
    const downloadFileName = `session_analytics_${startDate}_${endDate}`;

    getVideoWatchSessionsReport(payload)
      .then((res) => {
        JsFileDownloader(res, `${downloadFileName}.csv`);
      })
      .catch(() => {
        // to do
      });
  };

  return (
    <Card>
      <CardHeader
        title="Watched Minutes"
        subheader={`Number of ${CONTENT_ALIAS.VIDEO_PLURAL.toLowerCase()} watch minutes`}
        action={
          <IconButton title="Refresh" onClick={handleRefreshData}>
            <Iconify icon="ic:twotone-refresh" />
          </IconButton>
        }
      />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="right" spacing={2}>
              <Datepicker
                id="dr_start_date"
                name="dr_start_date"
                label="Start Date"
                defaultValue={filters.startDate}
                onChange={handleStartDateChange}
                sx={{ width: 200 }}
              />
              <Datepicker
                id="dr_end_date"
                name="dr_end_date"
                label="End Date"
                defaultValue={filters.endDate}
                onChange={handleEndDateChange}
                sx={{ width: 200 }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="right"
              spacing={2}
              style={{ marginTop: '10px' }}
            >
              {chartStatus === 'show' && (
                <Button
                  variant="contained"
                  sx={{ my: 2, marginRight: 2 }}
                  onClick={downloadReport}
                >
                  Download CSV
                </Button>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack sx={{ marginTop: 2 }}>
              {chartStatus === 'show' && (
                <ReactApexChart
                  type="line"
                  series={seriesData}
                  options={chartOptions}
                  height={364}
                />
              )}
              {chartStatus === 'loading' && <>Loading...</>}
              {chartStatus === 'nodata' && <>No data available.</>}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default WatchedSecondsChart;
