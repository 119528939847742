import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { IS_TRIAL } from '../../config/const.config';

const IstrialType = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  validOptions,
  sx,
  error,
  disabled,
}) => (
  <FormControl fullWidth error={error !== ''}>
    <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id={id}
      name={name}
      value={defaultValue}
      label={label}
      onChange={onChange}
      sx={sx}
      disabled={disabled}
    >
      {validOptions.map((k) => (
        <MenuItem key={`${id}-v-${k}`} value={k}>
          {IS_TRIAL[k]}
        </MenuItem>
      ))}
    </Select>
    {error !== '' && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

IstrialType.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  validOptions: PropTypes.array.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  disabled: PropTypes.bool,
};
IstrialType.defaultProps = {
  sx: {},
  error: '',
  disabled: false,
};

export default IstrialType;
