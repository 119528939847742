import React from 'react';
import AppLayout from '../../layout/app.layout';
import Logout from '../../components/auth/logout.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const LogoutView = () => {
  const pageTitle = PAGE_TITLE_LIST.LOGOUT;

  return (
    <AppLayout pageTitle={pageTitle}>
      <Logout />
    </AppLayout>
  );
};

export default LogoutView;
