import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ThemeProvider from '../theme/wrappers';
import AppRouter from '../routes/app.routes';
import {
  checkAdminUserLoggedIn,
  setAdminUserLoggedOut,
  setAdminUserLoggedIn,
} from '../utils/local-cache.util';
import { adminUserValidateSession } from '../services/auth.service';
import Loader from '../components/common/loader.component';

const AppView = () => {
  const [appLoaded, setAppLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const isAdminLoggedIn = checkAdminUserLoggedIn();

    if (isAdminLoggedIn) {
      adminUserValidateSession()
        .then((res) => {
          const loggedInUserPayload = {
            id: res.data?.id || 0,
            token: res.data?.auth?.token || '',
            name: res.data?.name || '',
            email: res.data?.email || '',
            role: res.data?.role || '',
          };
          setAdminUserLoggedIn(loggedInUserPayload);
          setIsLoggedIn(isAdminLoggedIn);
          setAppLoaded(true);
        })
        .catch(() => {
          setAdminUserLoggedOut();
          setAppLoaded(true);
        });
    } else {
      setAppLoaded(true);
    }
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          {!appLoaded && <Loader />}
          {appLoaded && <AppRouter isLoggedIn={isLoggedIn} />}
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default AppView;
