// Define subscription types
const SUBSCRIPTION_TYPE_LIST = [
  // {
  //   id: 'SVOD',
  //   title: 'SVOD',
  // },
  {
    id: 'TVOD',
    title: 'TVOD',
  },
];

export default SUBSCRIPTION_TYPE_LIST;
