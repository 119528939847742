import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListSubscription from '../../components/subscribers/list-subscription.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const SubscriptionsView = () => {
  const pageTitle = PAGE_TITLE_LIST.SUBSCRIPTIONS;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSubscription />
    </AppLayout>
  );
};

export default SubscriptionsView;
