import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListUserTestimonialText from '../../components/content/list-user-testimonial-text.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentUserTextView = () => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_USER_TESTIMONIALS_TEXT}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListUserTestimonialText />
    </AppLayout>
  );
};

ContentUserTextView.propTypes = {};

export default ContentUserTextView;
