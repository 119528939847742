import React from 'react';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListGroups from '../../components/groups/list-groups.component';

const GroupsView = () => {
  const pageTitle = PAGE_TITLE_LIST.GROUPS;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListGroups />
    </AppLayout>
  );
};

export default GroupsView;
