import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import LANGUAGE_LIST from '../../config/module-configs/language.config';

const LanguageTypes = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  sx,
  error,
  disabled,
}) => (
  <FormControl fullWidth error={error !== ''}>
    <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>

    <Select
      labelId="demo-simple-select-label"
      id={id}
      name={name}
      value={defaultValue}
      label={label}
      onChange={onChange}
      sx={sx}
      disabled={disabled}
    >
      {Object.keys(LANGUAGE_LIST).map((k) => (
        <MenuItem key={`${id}-v-${k}`} value={`${LANGUAGE_LIST[k]}-${k}`}>
          {`${LANGUAGE_LIST[k]} - (${k})`}
        </MenuItem>
      ))}
    </Select>

    {error !== '' && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

LanguageTypes.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  disabled: PropTypes.number,
};

LanguageTypes.defaultProps = {
  sx: {},
  error: '',
  disabled: null,
};

export default LanguageTypes;
