import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
import { VIEW_COLUMNS } from '../../config/module-configs/subscribers.config';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewData from '../table-elements/view-data.component';
import { viewDataById } from '../../services/subscribers.service';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ViewSubscriber = ({ title, user, dataId, onCancel }) => {
  const columns = VIEW_COLUMNS;

  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState(null);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  useEffect(() => {
    viewDataById(dataId)
      .then((res) => {
        setRowData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      onClose={() => onCancel()}
      aria-labelledby="view-dialog-title"
      aria-describedby="view-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        id="view-dialog-title"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {title}
        {!!dataId && user.profilePic && (
          <Box
            component="img"
            sx={{
              height: 30,
              width: 30,
              objectFit: 'cover',
              borderRadius: 15,
              display: 'inline',
              marginX: 1,
            }}
            src={user.profilePic}
          />
        )}
        {!!dataId && user.email}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="view-dialog-description">
        {loading && <Loading />}

        {!loading && <ViewData columns={columns} row={rowData} />}
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

ViewSubscriber.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ViewSubscriber;
