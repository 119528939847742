import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import ROUTE_PATH from '../../config/routes.config';
import logoImage from '../../assets/images/logo/logo_w.png';

const Logo = ({ disabledLink, sx }) => {
  if (disabledLink) {
    return (
      <Box
        component="img"
        src={logoImage}
        sx={{ cursor: 'pointer', ...sx }}
        style={{
          filter:
            'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(293deg) brightness(102%) contrast(104%)',
        }}
      />
    );
  }

  return (
    <Link
      to={ROUTE_PATH.DASHBOARD}
      component={RouterLink}
      sx={{ display: 'contents' }}
    >
      <Box
        component="img"
        src={logoImage}
        sx={{ cursor: 'pointer', ...sx }}
        style={{
          filter:
            'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(293deg) brightness(102%) contrast(104%)',
        }}
      />
    </Link>
  );
};

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};
Logo.defaultProps = {
  sx: {},
  disabledLink: true,
};

export default Logo;
