import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { getRoleLabel } from '../../config/roles.config';

const RoleLabel = ({ value }) => (
  <Typography variant="body2" component="span">
    {getRoleLabel(value)}
  </Typography>
);

RoleLabel.propTypes = {
  value: PropTypes.string.isRequired,
};

export default RoleLabel;
