import React from 'react';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Typography, Box, Button } from '@mui/material';
import ROUTE_PATH from '../../config/routes.config';
import EmptyLayout from '../../layout/empty.layout';
import notFoundImage from '../../assets/images/illustrations/illustration_404.svg';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const PageNotFoundView = () => {
  const pageTitle = PAGE_TITLE_LIST.ERROR_NOT_FOUND;

  return (
    <EmptyLayout pageTitle={pageTitle}>
      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn&apos;t find the page you&apos;re looking for.
            Perhaps you&apos;ve mistyped the URL? Be sure to check your
            spelling.
          </Typography>

          <Box
            component="img"
            src={notFoundImage}
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <Button
            to={ROUTE_PATH.DASHBOARD}
            size="large"
            variant="contained"
            component={RouterLink}
          >
            Go to Home
          </Button>
        </StyledContent>
      </Container>
    </EmptyLayout>
  );
};

export default PageNotFoundView;
