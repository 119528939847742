import * as tus from 'tus-js-client';
import { uploadVideoToBunnyCDN } from '../services/content.service';

let upload;

export const getVideoFileUploadHeader = async (
  videoId,
  videoTitle,
  videoType
) => {
  const payload = {
    videoId,
    videoTitle,
    videoType,
  };
  const cdnResponse = await uploadVideoToBunnyCDN(payload);

  if (cdnResponse && cdnResponse?.data?.guid) {
    // const videoURL = cdnResponse?.data?.videoUrl || '';

    return {
      guid: cdnResponse?.data?.guid || '',
      libraryId: cdnResponse?.data?.libraryId || null,
      authorizationSignature: cdnResponse?.data?.authorizationSignature || '',
      authorizationExpire: cdnResponse?.data?.authorizationExpire || 0,
      cdnCollectionId: cdnResponse?.data?.cdnCollectionId || '',
    };
  }

  return '';
};

export const startVideoFileUpload = (
  file,
  fileUploadHeader,
  videoTitle,
  progressBar,
  showToastMsg
) => {
  const endpoint = 'https://video.bunnycdn.com/tusupload';
  const options = {
    endpoint,
    // retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
    retryDelays: [0, 3000, 5000],
    headers: {
      AuthorizationSignature: fileUploadHeader.authorizationSignature,
      AuthorizationExpire: fileUploadHeader.authorizationExpire,
      VideoId: fileUploadHeader.guid,
      LibraryId: fileUploadHeader.libraryId,
    },
    metadata: {
      filetype: file.type,
      title: videoTitle,
      collection: fileUploadHeader.cdnCollectionId,
    },
    onError() {
      showToastMsg('error', 'Something went wrong! Please try again.');
      upload = null;
    },
    onProgress(bytesUploaded, bytesTotal) {
      let percentage = Math.floor((bytesUploaded * 100) / bytesTotal);
      if (percentage === 100) {
        percentage = 99;
      }
      progressBar(percentage);
    },
    onSuccess() {
      progressBar(100);
      showToastMsg('success', 'Video Uploaded Successfully!');
      upload = null;
    },
  };

  upload = new tus.Upload(file, options);
  upload.findPreviousUploads().then(() => {
    // askToResumeUpload(previousUploads, upload);
    upload.start();
  });
};

// export const askToResumeUpload = (previousUploads, upload) => {
//   if (previousUploads.length === 0) return;

//   let text = 'You tried to upload this file previously at these times:\n\n';
//   previousUploads.forEach((previousUpload, index) => {
//     text += `[${index}] ${previousUpload.creationTime}\n`;
//   });
//   text +=
//     '\nEnter the corresponding number to resume an upload or press Cancel to start a new upload';

//   const answer = prompt(text);
//   const index = parseInt(answer, 10);

//   if (!Number.isNaN(index) && previousUploads[index]) {
//     upload.resumeFromPreviousUpload(previousUploads[index]);
//   }
// };
