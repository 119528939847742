import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListCategories from '../../components/content/list-categories.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentCategoriesView = () => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_CATEGORY}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListCategories />
    </AppLayout>
  );
};

ContentCategoriesView.propTypes = {};

export default ContentCategoriesView;
