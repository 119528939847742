import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { getSearchListData } from '../../services/subscribers.service';

const Subscribers = ({
  id,
  name,
  label,
  defaultValue,
  multiple,
  onChange,
  sx,
  error,
  disabled,
  moderator,
}) => {
  const selectedValue = defaultValue || (multiple ? [] : null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(selectedValue);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    let param = `?q=${inputValue}`;
    if (moderator) {
      param += '&moderator=1';
    }

    getSearchListData(param)
      .then((res) => {
        setOptions(res?.data || []);
        setLoading(false);
      })
      .catch(() => {
        // upon error, show loading only
      });
  }, [inputValue]);

  return (
    <Autocomplete
      disablePortal
      id={id}
      name={name}
      options={options}
      getOptionLabel={(option) =>
        `${option.firstName} ${option.lastName} (${option.email})`
      }
      sx={sx}
      value={value}
      onChange={(e, v) => {
        setValue(v);
        onChange(v);
      }}
      inputValue={inputValue}
      onInputChange={(e, v) => {
        setInputValue(v);
      }}
      disabled={disabled}
      multiple={multiple}
      loading={loading}
      isOptionEqualToValue={(op, vl) => op.id === vl.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error !== ''}
          helperText={error}
        />
      )}
    />
  );
};

Subscribers.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  disabled: PropTypes.number,
  moderator: PropTypes.bool,
};
Subscribers.defaultProps = {
  multiple: false,
  sx: {},
  defaultValue: null,
  error: '',
  disabled: null,
  moderator: false,
};

export default Subscribers;
