import React from 'react';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListPopularContent from '../../components/content/list-popular-content.component';

const PopularContentView = () => {
  const pageTitle = `${PAGE_TITLE_LIST.POPULAR_CONTENT}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListPopularContent />
    </AppLayout>
  );
};

PopularContentView.propTypes = {};

export default PopularContentView;
