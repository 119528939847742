/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { WEEK_NAME } from '../../config/const.config';

const DayNames = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  validOptions,
  selectedDays,
  handleDayChange,
  sx,
  error,
  disabled,
}) => (
  <FormControl fullWidth error={error !== ''}>
    <Typography>{label}:</Typography>
    <div style={{ display: 'flex' }}>
      {validOptions.map((k) => (
        <FormControlLabel
          key={k}
          control={
            <Checkbox
              checked={selectedDays.includes(k)}
              onChange={() => handleDayChange(k)}
            />
          }
          label={WEEK_NAME[k]}
        />
      ))}
    </div>

    {error !== '' && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

DayNames.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  validOptions: PropTypes.array.isRequired,
  selectedDays: PropTypes.array,
  handleDayChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  disabled: PropTypes.number,
};
DayNames.defaultProps = {
  selectedDays: [],

  sx: {},
  error: '',
  disabled: null,
};

export default DayNames;
