import React from 'react';
import { Typography } from '@mui/material';

const NoData = () => (
  <Typography variant="body2" sx={{ my: 20 }}>
    No data found
  </Typography>
);

export default NoData;
