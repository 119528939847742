// Get avatar two letters for name
const getNameInitials = (word) =>
  word
    .match(/(\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toUpperCase();

export default getNameInitials;
