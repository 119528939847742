import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

const Loader = () => (
  <Backdrop sx={{ color: '#2065D1', background: 'transparent' }} open>
    <CircularProgress disableShrink />
  </Backdrop>
);

export default Loader;
