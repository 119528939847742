import React from 'react';
import { Typography } from '@mui/material';

const Error = () => (
  <Typography variant="body2" sx={{ my: 20 }}>
    Something went wrong. Please try again.
  </Typography>
);

export default Error;
