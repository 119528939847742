import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions,
  Button,
  Grid,
  Box,
  ImageList,
  ImageListItem,
  CircularProgress,
} from '@mui/material';
import Dropzone from 'react-dropzone';
import { validateSelectedImageFile } from '../../utils/file-validations.util';
import { uploadImageToBunnyCDN } from '../../services/content.service';

const ImageUpload = ({
  title,
  dataId,
  showToastMsg,
  setSnackbarInfo,
  videoData,
  loading,
  showUpdatedImage,
  isTranslation,
  setImageUrlCallBack,
  category,
  popularContent,
  bannerType,
  isCalendarEventPoster,
  isCommunityGathering,
  uploadFrom,
  setFileData,
  isArticleContent,
  communityGroup,
  contentType,
}) => {
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFilesObj, setSelectedFilesObj] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showUploading, setShowUploading] = useState(false);
  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSelectFiles = async (files) => {
    showUpdatedImage(false);
    setSelectedFilesObj(files);
    setBtnDisabled(false);
    setSnackbarInfo({
      show: false,
    });
    await sleep(100);

    const validationError = validateSelectedImageFile(files);
    if (validationError) {
      showToastMsg('error', validationError);
    }

    // Show file name below button
    for (let i = 0; i < files.length; i += 1) {
      setSelectedFileName(files[i].name);
    }
    if (uploadFrom === 'form') {
      setFileData({
        selectedFile: files,
        fileName: files[0].name,
      });
    }
  };

  const getImageRelatedData = () => {
    if (title === 'Square Poster') {
      return {
        aspectRatio: '1:1',
        sampleWidthHeight: '449 x 449',
        title: 'square poster',
      };
    }
    if (title === 'Horizontal Poster') {
      return {
        aspectRatio: '10:3',
        sampleWidthHeight: '1401 x 420',
        title: 'horizontal poster',
      };
    }
    if (title === 'Poster' && isArticleContent) {
      return {
        aspectRatio: '16:9',
        sampleWidthHeight: '459 x 258',
        title: 'poster',
      };
    }
    if (title === 'Poster' && communityGroup) {
      return {
        aspectRatio: '16:9',
        sampleWidthHeight: '377 x 212',
        title: 'poster',
      };
    }

    if (title === 'Poster') {
      return {
        aspectRatio: '16:9',
        sampleWidthHeight: '472 x 265',
        title: 'poster',
      };
    }

    if (title === 'Thumbnail' && contentType === 'user-testimonial') {
      return {
        aspectRatio: '16:9',
        sampleWidthHeight: '424 x 238',
        title: 'poster',
      };
    }

    return {
      aspectRatio: '16:9',
      sampleWidthHeight: '377 x 212',
      title: 'thumbnail',
    };
  };
  const handleUploadFiles = async (id, selectedImageFileName) => {
    setSnackbarInfo({
      show: false,
    });
    await sleep(100);

    const validationError = validateSelectedImageFile(selectedFilesObj);
    if (validationError) {
      showToastMsg('error', validationError);
    } else {
      setBtnDisabled(true);
      setShowUploading(true);
      const imageFormData = new FormData();
      let keyId = 'videoId';
      if (
        (title === 'Poster' || title === 'Horizontal Poster') &&
        isArticleContent
      ) {
        keyId = 'articleContentId';
        imageFormData.append('bannerType', bannerType);
      } else if (title === 'Poster' && isCalendarEventPoster) {
        keyId = 'eventId';
      } else if (title === 'Poster' && communityGroup) {
        keyId = 'communityGroupId';
      } else if (title === 'Poster' && category) {
        keyId = 'programId';
      } else if (
        (title === 'Square Poster' || title === 'Horizontal Poster') &&
        popularContent
      ) {
        keyId = 'popularContentId';
      } else if (title === 'Poster' && !category) {
        keyId = 'groupId';
      } else if (title === 'Thumbnail' && isCommunityGathering) {
        keyId = 'communityGatheringId';
      }
      imageFormData.append(keyId, id);
      imageFormData.append('file', selectedFilesObj[0]);
      imageFormData.append('imageType', title);
      if (
        (title === 'Square Poster' || title === 'Horizontal Poster') &&
        popularContent
      ) {
        imageFormData.append('bannerType', bannerType);
      }
      imageFormData.append('selectedImageFileName', selectedImageFileName);
      try {
        const type = isTranslation
          ? `lang?lang=${videoData.languageCode}`
          : 'orig';
        const cdnResponse = await uploadImageToBunnyCDN(imageFormData, type);
        if (cdnResponse.data?.success) {
          showUpdatedImage(true);
          setSelectedFilesObj(null);
          setBtnDisabled(false);
          setSelectedFileName('');
          showToastMsg('success', `${title} Uploaded Successfully!`);
          setImageUrlCallBack(
            {
              languageCode: videoData?.languageCode,
              imageUrl: cdnResponse.data?.imageUrl,
            },
            bannerType
          );
        } else {
          showToastMsg('error', 'Something went wrong! Please try again.');
        }
      } catch {
        showToastMsg('error', 'Something went wrong! Please try again.');
      }

      setShowUploading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{
          whiteSpace: 'unset',
          wordBreak: 'break-all',
        }}
      >
        <Grid item sx={{ fontWeight: 'bold' }}>
          {`${title}:`}
        </Grid>
        <Grid item xs={12} sm={6} md={6} style={{ maxWidth: '100%' }}>
          {loading && (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          )}

          {!loading && (
            <ImageList>
              <ImageListItem>
                {(videoData.thumbnail ||
                  videoData.posterURL ||
                  (videoData.banner && bannerType === '')) &&
                  (title === 'Thumbnail' ||
                    title === 'Poster' ||
                    title === 'Square Poster' ||
                    title === 'Horizontal Poster') && (
                    <img
                      src={
                        videoData.thumbnail ||
                        videoData.posterURL ||
                        videoData.banner
                      }
                      alt={title}
                      loading="lazy"
                      style={{ objectFit: 'contain' }}
                    />
                  )}
                {videoData.heroImage && title === 'Hero Image' && (
                  <img
                    src={videoData.heroImage}
                    alt={title}
                    loading="lazy"
                    style={{ objectFit: 'contain' }}
                  />
                )}
                {bannerType === 'square' &&
                  (videoData.squareBanner || videoData.banner) && (
                    <img
                      src={videoData.squareBanner || videoData.banner || ''}
                      alt={title}
                      loading="lazy"
                      style={{ objectFit: 'contain' }}
                    />
                  )}
                {bannerType === 'horizontal' && videoData.horizBanner && (
                  <img
                    src={videoData.horizBanner || ''}
                    alt={title}
                    loading="lazy"
                    style={{ objectFit: 'contain' }}
                  />
                )}
              </ImageListItem>
            </ImageList>
          )}
        </Grid>
        <Grid item>
          <Box sx={{ fontWeight: 'bold' }}>{`${title} URL:`}&nbsp;</Box>
          <Box sx={{ fontSize: '15px' }}>
            {title === 'Thumbnail' ||
            title === 'Poster' ||
            title === 'Square Poster' ||
            title === 'Horizontal Poster'
              ? videoData.thumbnail ||
                videoData.posterURL ||
                (bannerType === '' && videoData.banner) ||
                (bannerType === 'square' &&
                  (videoData.squareBanner || videoData.banner)) ||
                (bannerType === 'horizontal' && videoData.horizBanner)
              : videoData.heroImage}
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={8} md={8}>
        <Grid item xs={12} sm={12} md={12}>
          <Dropzone
            onDrop={(acceptedFiles) => handleSelectFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />

                  <Box
                    component="section"
                    sx={{
                      p: 3,
                      border: '1px dashed grey',
                      borderRadius: '20px',
                      width: '100%',
                      marginTop: 3,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          fontWeight: 'bold',
                          margin: '0px',
                          padding: '5px',
                        }}
                      >
                        {`Add/Replace ${title}`}
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          Drag and drop image files here, or click to browse
                        </Grid>

                        <DialogActions sx={{ justifyContent: 'center' }}>
                          {!showUploading && (
                            <Button
                              component="label"
                              variant="contained"
                              sx={{
                                backgroundColor: '#808080',
                              }}
                            >
                              {`Select ${title}`}
                            </Button>
                          )}

                          {showUploading && (
                            <Box
                              display="flex"
                              alignItems="center"
                              padding="6px 16px"
                              borderRadius="6px"
                              sx={{
                                backgroundColor: '#919eab3d',
                                color: '#919eabcc',
                              }}
                            >
                              <CircularProgress
                                disableShrink
                                size="18px"
                                sx={{ marginRight: 1, color: '#919eabcc' }}
                              />
                              <span>Uploading...</span>
                            </Box>
                          )}
                        </DialogActions>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {`${selectedFileName}`}
                    </Grid>
                  </Box>
                </div>
              </section>
            )}
          </Dropzone>
        </Grid>

        {uploadFrom !== 'form' && (
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              color="primary"
              variant="contained"
              disabled={btnDisabled}
              onClick={() => handleUploadFiles(dataId, selectedFileName)}
            >
              {`Upload ${title}`}
            </Button>
          </DialogActions>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <b>Note:&nbsp;</b>
          {`Please upload ${getImageRelatedData().title} with ${
            getImageRelatedData().aspectRatio
          } aspect ratio (e.g.
          ${getImageRelatedData().sampleWidthHeight}).`}
        </Grid>
      </Grid>
    </Grid>
  );
};

ImageUpload.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  showToastMsg: PropTypes.func.isRequired,
  setSnackbarInfo: PropTypes.func.isRequired,
  videoData: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  showUpdatedImage: PropTypes.func.isRequired,
  setImageUrlCallBack: PropTypes.func,
  isTranslation: PropTypes.bool,
  category: PropTypes.bool,
  popularContent: PropTypes.bool,
  bannerType: PropTypes.string,
  isCalendarEventPoster: PropTypes.bool,
  isCommunityGathering: PropTypes.bool,
  uploadFrom: PropTypes.string,
  setFileData: PropTypes.func,
  isArticleContent: PropTypes.bool,
  communityGroup: PropTypes.bool,
  contentType: PropTypes.string.isRequired,
};

ImageUpload.defaultProps = {
  setImageUrlCallBack: () => {},
  isTranslation: false,
  category: false,
  popularContent: false,
  bannerType: '',
  isCalendarEventPoster: false,
  isCommunityGathering: false,
  isArticleContent: false,
  uploadFrom: '',
  setFileData: () => {},
  communityGroup: false,
};

export default ImageUpload;
