import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListTestimonialTabs from '../../components/content/list-testimonial-tabs.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentCategoriesView = () => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_TESTIMONIALS_TABS}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListTestimonialTabs />
    </AppLayout>
  );
};

ContentCategoriesView.propTypes = {};

export default ContentCategoriesView;
