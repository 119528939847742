import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Iconify from '../common/iconify.component';

const SearchList = ({
  label,
  handleSearch,
  inputRef,
  onChange,
  options,
  clearSearch,
  loading,
  width,
}) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      if (!loading) {
        handleSearch();
      }
    }}
    autoComplete="off"
    style={{
      display: 'inline-flex',
      flexDirection: 'row',
      marginRight: '10px',
    }}
  >
    <TextField
      label={`Search ${label}`}
      type="text"
      inputRef={inputRef}
      onChange={onChange}
      onBlur={!options.searchValue && handleSearch}
      sx={{
        width,
        marginLeft: '5px',
        '.MuiInputLabel-root.MuiInputLabel-shrink.MuiFormLabel-filled': {
          top: '0px',
        },
        '.MuiInputLabel-root': {
          top: '-8px',
        },
      }}
      InputProps={{
        style: {
          height: '2.25em',
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              color="primary"
              onClick={handleSearch}
              disabled={loading}
            >
              <Iconify icon="fe:search" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: !(inputRef.current?.value === '' || inputRef.current === null),
      }}
    />

    {options.searchValue && (
      <IconButton onClick={() => clearSearch()} edge="end" color="primary">
        <Iconify icon="mdi:close" />
      </IconButton>
    )}
  </form>
);

SearchList.propTypes = {
  label: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  inputRef: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  clearSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  width: PropTypes.string,
};

SearchList.defaultProps = {
  loading: false,
  width: '200px',
};

export default SearchList;
