import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Packages module configurations
export const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    width: '10%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    width: '20%',
    align: 'left',
    dataKey: 'description',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'accessType',
    label: 'Access Type',
    width: '10%',
    align: 'left',
    dataKey: 'accessType',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'price',
    label: 'Price (CAD)',
    width: '10%',
    align: 'left',
    dataKey: 'price',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '10%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
];

export const VIEW_PACKAGES_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'accessType',
    label: 'Package Type',
    dataKey: 'accessType',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'subscriptionDays',
    label: 'Subscription Days',
    dataKey: 'subscriptionDays',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'maxSubscriptionDays',
    label: 'Max Subscription Days',
    dataKey: 'maxSubscriptionDays',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'price',
    label: 'Price (CAD)',
    dataKey: 'price',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
