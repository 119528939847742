import React from 'react';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListSections from '../../components/content/list-sections.component';

const ContentSectionsView = () => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_SECTION}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSections />
    </AppLayout>
  );
};

ContentSectionsView.propTypes = {};

export default ContentSectionsView;
