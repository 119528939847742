import React from 'react';
import {
  Typography,
  Chip,
  Box,
  IconButton,
  Switch,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import CELL_DATA_TYPES from '../../config/cell-types.config';
import {
  formatNumber,
  formatPercent,
  formatDuration,
  formatCurrency,
} from '../../utils/numbers.util';
import { formatDate, formatTimestamp } from '../../utils/datetime.util';
import StatusLabel from './status-label.component';
import PlatformLabel from './platform-label.component';
import Iconify from '../common/iconify.component';
import { APP_THEME } from '../../theme/theme-colors';
import RoleLabel from './role-label.component';
import { CATEGORY_TYPE, VIDEO_TYPES } from '../../config/const.config';

const categoryTypes = Object.values([...CATEGORY_TYPE]).map((el) => {
  const element = el;
  element.value = element.id;
  return element;
});
const videoTypes = Object.values(VIDEO_TYPES);
const CellValue = ({
  type,
  value,
  fallbackValue,
  cdnStatus,
  disabledToggle,
}) => {
  if (type === CELL_DATA_TYPES.NUMBER) {
    return (
      <Typography variant="body2" component="span">
        {formatNumber(value || 0)}
      </Typography>
    );
  }

  if (type === CELL_DATA_TYPES.PERCENTAGE) {
    return (
      <Typography variant="body2" component="span">
        {formatPercent(value || 0)}
      </Typography>
    );
  }

  if (type === CELL_DATA_TYPES.PROGRESS) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          value={value}
          thickness={4}
          size={48}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: 'text.primary' }}
          >
            {`${Math.round(value || 0)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  if (type === CELL_DATA_TYPES.MONEY) {
    return (
      <Typography variant="body2" component="span">
        CA${formatCurrency(value || 0)}
      </Typography>
    );
  }

  if (type === CELL_DATA_TYPES.DURATION) {
    return (
      <Typography variant="body2" component="span">
        {formatDuration(value || 0)}
      </Typography>
    );
  }

  if (type === CELL_DATA_TYPES.DATE) {
    return (
      <Typography variant="body2" component="span">
        {formatDate(value || null, 'll')}
      </Typography>
    );
  }

  if (type === CELL_DATA_TYPES.DATETIME) {
    return (
      <Typography variant="body2" component="span">
        {formatDate(value || null, 'lll')}
      </Typography>
    );
  }

  if (type === CELL_DATA_TYPES.TIMESTAMP) {
    return (
      <Typography variant="body2" component="span">
        {formatTimestamp(value || null, 'lll')}
      </Typography>
    );
  }

  if (type === CELL_DATA_TYPES.BOOL) {
    return (
      <Chip
        label={Number(value) === 1 ? 'Yes' : 'No'}
        color={Number(value) === 1 ? 'success' : 'error'}
        variant="filled"
        size="small"
      />
    );
  }

  if (type === CELL_DATA_TYPES.PLATFORM) {
    return <PlatformLabel value={value} />;
  }

  if (type === CELL_DATA_TYPES.ROLE) {
    return <RoleLabel value={value} />;
  }

  if (type === CELL_DATA_TYPES.SWITCH) {
    return (
      <Switch
        aria-label="status"
        color="primary"
        defaultChecked={!!value}
        onChange={(e) => fallbackValue(e)}
        disabled={disabledToggle}
      />
    );
  }

  if (type === CELL_DATA_TYPES.STATUS) {
    return <StatusLabel value={value} />;
  }

  if (type === CELL_DATA_TYPES.CODE) {
    return (
      <Box component="span" sx={{ display: 'block' }}>
        {value || ''}
      </Box>
    );
  }

  if (type === CELL_DATA_TYPES.PREVIEW) {
    return (
      <IconButton
        aria-label="preview"
        disabled={cdnStatus !== 'Finished'}
        sx={{ padding: 0, color: APP_THEME }}
      >
        <Iconify icon="solar:play-bold" />
      </IconButton>
    );
  }

  if (type === CELL_DATA_TYPES.IMAGE) {
    if (value === null || value === '') {
      return (
        <Box
          sx={{
            height: 54,
            width: 96,
            bgcolor: '#808080',
          }}
        />
      );
    }

    return (
      <Box
        component="img"
        sx={{
          height: 54,
          width: 96,
          objectFit: 'contain',
        }}
        src={value}
      />
    );
  }

  if (type === CELL_DATA_TYPES.PROFILE) {
    if (value === null || value === '') {
      return (
        <Box
          sx={{
            height: 36,
            width: 36,
            bgcolor: '#808080',
            borderRadius: 18,
          }}
        />
      );
    }

    return (
      <Box
        component="img"
        sx={{
          height: 36,
          width: 36,
          objectFit: 'cover',
          borderRadius: 18,
        }}
        src={value}
      />
    );
  }

  if (type === CELL_DATA_TYPES.UCWORDS) {
    return (
      <Box component="span" sx={{ display: 'block' }}>
        {
          [...categoryTypes, ...videoTypes].find((el) => el.value === value)
            .label
        }
      </Box>
    );
  }

  return (
    <Typography variant="body2" component="span">
      {value || ''}
    </Typography>
  );
};

CellValue.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.any,
  fallbackValue: PropTypes.func,
  cdnStatus: PropTypes.string,
  disabledToggle: PropTypes.bool,
};
CellValue.defaultProps = {
  value: null,
  fallbackValue: () => {},
  cdnStatus: null,
  disabledToggle: false,
};

export default CellValue;
