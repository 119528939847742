import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, CONTENT_ALIAS } from '../const.config';

// Sections module configurations
export const LISTING_COLUMNS = [
  {
    id: 'poster',
    label: 'Poster Preview',
    width: '10%',
    align: 'left',
    dataKey: 'poster',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
  },
  {
    id: 'name',
    label: 'Title',
    width: '20%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'name',
    label: `Parent ${CONTENT_ALIAS.SECTION_SINGULAR}`,
    width: '20%',
    align: 'left',
    dataKey: 'parentTitle',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.TRANSLATE.value,
    label: ACTIONS.TRANSLATE.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.UPLOAD_POSTER.value,
    label: ACTIONS.UPLOAD_POSTER.label,
    conditional: (val) => val.parentTitle,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_SECTIONS_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'title',
    label: `Parent ${CONTENT_ALIAS.SECTION_SINGULAR}`,
    dataKey: 'parentTitle',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'poster',
    label: 'Poster URL',
    dataKey: 'posterURL',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
