import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const createNotification = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/settings/notification/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateNotification = async (id, payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/settings/notification/${id}/update/${type}`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewNotification = async (id = 0, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/settings/notification/${id}/view/${type}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createSalutation = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/settings/salutation/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateSalutation = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/settings/salutation/update',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewSalutation = async () => {
  const options = prepareRequestOptions(
    '/admin/settings/salutation/view',
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
